import { ACTIONS } from '../constants/actions';

export const setIpAddress = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.IP_ADDRESS.IP_ADDRESS_SET,
  });

export const clearIpAddress = (dispatch) =>
  dispatch({
    type: ACTIONS.IP_ADDRESS.IP_ADDRESS_CLEAR,
  });
