const IconWithdraw = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 14.6354C9.56852 14.6354 9.21875 14.2857 9.21875 13.8542L9.21875 8.05797L7.47953 9.79719C7.17441 10.1023 6.67973 10.1023 6.37465 9.79719C6.06953 9.49203 6.06961 8.99742 6.37465 8.69234L9.44758 5.61941C9.7532 5.31387 10.2476 5.31465 10.5525 5.61941L13.6254 8.69234C13.9304 8.99746 13.9305 9.49211 13.6254 9.79719C13.3203 10.1023 12.8256 10.1023 12.5205 9.79719L10.7812 8.05797L10.7812 13.8542C10.7812 14.2857 10.4315 14.6354 10 14.6354ZM14.2042 20L5.79578 20C3.12469 20 1.7682 18.1886 1.60176 18.0712C0.568828 17.0384 -6.84739e-07 15.665 -6.20886e-07 14.2042L-2.53342e-07 5.79578C-1.89489e-07 4.335 0.568867 2.96168 1.6018 1.92879C1.76652 1.81254 3.12445 -7.37654e-07 5.79578 -6.20886e-07L14.2042 -2.53342e-07C16.8752 -1.36588e-07 18.2319 1.81145 18.3982 1.92883C19.4311 2.96168 20 4.33504 20 5.79578L20 14.2042C20 15.665 19.4311 17.0384 18.3982 18.0712C18.2337 18.1873 16.8756 20 14.2042 20ZM14.2042 18.4375C16.1616 18.4375 17.1282 17.0851 17.2934 16.9663C18.0312 16.2286 18.4375 15.2476 18.4375 14.2042L18.4375 5.79578C18.4375 4.75238 18.0312 3.77145 17.2934 3.03367C17.1222 2.91055 16.172 1.5625 14.2042 1.5625L5.79578 1.5625C3.8384 1.5625 2.87207 2.91465 2.70664 3.03363C1.96883 3.77144 1.5625 4.75238 1.5625 5.79578L1.5625 14.2042C1.5625 15.2476 1.96879 16.2286 2.7066 16.9663C2.87777 17.0894 3.82801 18.4375 5.79578 18.4375L14.2042 18.4375Z"
        fill="#939BB1"
      />
    </svg>
  );
};

export default IconWithdraw;
