import useAxios from 'axios-hooks';

//common
import { METHODS } from '../common/constants/keys';
import { API } from '../common/constants/api';

const { HOST, APIBASE, INVITE_REFERRAL_SPIN } = API;

export function useShowFloatingIcon() {
  //return true/false
  const [{ data, loading, error }, showFloatingIcon] = useAxios(
    {
      url: `${HOST}${APIBASE}${INVITE_REFERRAL_SPIN.SHOW_FLOATING_ICON}`,
      method: METHODS.GET,
    },
    { manual: true },
  );

  return [{ data, loading, error }, showFloatingIcon];
}

export function useFetchPageDetail() {
  /* returns
  {
      "isActive": true,
      "isFirstSpin": false,
      "accumulatedAmount": 1981.0000,
      "targetAmount": 2000.000000,
      "expiryDate": "2024-04-18T16:58:57",
      "remainingCount": 0
  }
  */
  const [{ data, loading, error }, fetchPageDetail] = useAxios(
    {
      url: `${HOST}${APIBASE}${INVITE_REFERRAL_SPIN.PAGE_DETAIL}`,
      method: METHODS.GET,
    },
    { manual: true },
  );

  return [{ data, loading, error }, fetchPageDetail];
}

export function useSpinBonus() {
  /* returns
  {
    "success": false,
    "errorMessage": "User has used up all spin.",
    "awardAmount": 0.0
} */
  const [{ data, loading, error }, spinBonus] = useAxios(
    {
      url: `${HOST}${APIBASE}${INVITE_REFERRAL_SPIN.SPIN}`,
      method: METHODS.POST,
    },
    { manual: true },
  );

  return [{ data, loading, error }, spinBonus];
}

export function useClaimBonus() {
  //returns true/false
  const [{ data, loading, error }, claimBonus] = useAxios(
    {
      url: `${HOST}${APIBASE}${INVITE_REFERRAL_SPIN.CLAIM}`,
      method: METHODS.POST,
    },
    { manual: true },
  );

  return [{ data, loading, error }, claimBonus];
}

export function useFetchLuckyUserList() {
  /*returns
  [
    {
        "loginName": "ha*aha",
        "prize": "2,000.00"
    },
    {
        "loginName": "ha*aha",
        "prize": "2,000.00"
    }...
] */
  const [{ data, loading, error }, fetchLuckyUserList] = useAxios(
    {
      url: `${HOST}${APIBASE}${INVITE_REFERRAL_SPIN.LUCKY_USER_LIST}`,
      method: METHODS.GET,
    },
    { manual: true },
  );

  return [{ data, loading, error }, fetchLuckyUserList];
}

export function useFetchRegisterRecordList() {
  /* returns
[
    {
        "loginName": "da*sd2",
        "amountRequired": 300.000000,
        "amountDeposited": 200.0000,
        "registerDate": "2024-04-11T07:32:21",
        "inviteFlag": false,
        "message": "Need to recharge Rp 300, still need 100"
    },
    {
        "loginName": "hd****hd1",
        "amountRequired": 300.000000,
        "amountDeposited": 600.0000,
        "registerDate": "2024-04-11T10:12:29",
        "inviteFlag": true,
        "message": "Requirements Fulfilled"
    }...
] */

  const [{ data, loading, error }, fetchRegisterRecordList] = useAxios(
    {
      url: `${HOST}${APIBASE}${INVITE_REFERRAL_SPIN.REGISTER_RECORD_LIST}`,
      method: METHODS.GET,
    },
    { manual: true },
  );

  return [{ data, loading, error }, fetchRegisterRecordList];
}
