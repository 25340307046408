import { ACTIONS } from '../constants/actions';

export default function messageReducer(message, { type, payload = null }) {
    switch (type) {
        case ACTIONS.MESSAGE.MESSAGE_UNREAD_COUNT:
            return {
                ...message,
                ...payload,
            };
        case ACTIONS.MESSAGE.MESSAGE_CLEAR:
            return undefined;
        default:
            return message;
    }
}
