import useAxios from 'axios-hooks';

import { API } from '../common/constants/api';

const { HOST, APIBASE, BANNER, POPUPBANNER, EVENTURL } = API;

export function useGetBannerList(type) {
  const [{ data, loading, error }] = useAxios({
    url: `${HOST}${APIBASE}${BANNER.GET.replace(':type', type)}`, //1 = mobile 2 = desktop
  });

  return [{ data, loading, error }];
}

export function useGetPopupBanner(type = 1) {
  const [{ data, loading, error }] = useAxios({
    url: `${HOST}${APIBASE}${POPUPBANNER.GET.replace(':type', type)}`, //1 = mobile
  });

  return [{ data, loading, error }];
}

export function useGetEventUrl() {
  const [{ data, loading, error }] = useAxios({
    url: `${HOST}${APIBASE}${EVENTURL.GET}`,
  });

  return [{ data, loading, error }];
}

export function useGetBannerListVersion() {
  const [{ data, loading, error }, fetch] = useAxios(
    {
      url: `${HOST}${APIBASE}${BANNER.BANNER_CACHE_VERSION}`,
    },
    { manual: true },
  );

  return [{ data, loading, error }, fetch];
}

export function useGetPopupBannerListVersion() {
  const [{ data, loading, error }, fetch] = useAxios(
    {
      url: `${HOST}${APIBASE}${POPUPBANNER.POPUP_BANNER_CACHE_VERSION}`,
    },
    { manual: true },
  );

  return [{ data, loading, error }, fetch];
}