const IconMoney = ({ width = 32, height = 32 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.3489 26.9537H28.2734C28.2809 26.5378 28.2885 26.098 28.2885 25.5941C28.2885 13.5022 22.2441 9.59947 18.2169 8H13.9027C10.1552 9.59947 4.11072 12.7024 4.11072 25.5941C4.11072 26.098 4.11072 26.5458 4.12583 26.9457L4.05028 26.9617C3.21916 27.0976 2.59961 27.8574 2.59961 28.7531C2.59961 29.6488 3.27205 30.4645 4.1485 30.5525L7.46539 30.7444C9.66405 31.5122 12.7618 32 16.1996 32C19.6374 32 22.7352 31.5202 24.9338 30.7444L28.2507 30.5525C29.1272 30.4645 29.7996 29.6808 29.7996 28.7531C29.7996 27.8254 29.1801 27.0976 28.3489 26.9617V26.9537ZM15.6556 17.8127L17.6201 18.8844C18.6854 19.4682 19.2596 20.6598 19.0632 21.9314C18.9045 22.979 18.2547 23.7947 17.3632 24.1386V24.6025C17.3632 25.1543 16.9401 25.6021 16.4187 25.6021C15.8974 25.6021 15.4743 25.1543 15.4743 24.6025V24.2026C14.5223 23.9307 13.7138 23.2189 13.3058 22.2193C13.0943 21.7154 13.3134 21.1236 13.7969 20.8997C14.2729 20.6838 14.8321 20.9157 15.0361 21.4195C15.2778 22.0193 15.8521 22.3792 16.4641 22.3152H16.5547C17.0534 22.2193 17.1667 21.7954 17.1969 21.6115C17.2423 21.2916 17.1516 20.8757 16.7512 20.6598L14.7867 19.5881C13.7063 18.9963 13.1321 17.7488 13.3587 16.4852C13.5703 15.3016 14.3863 14.4459 15.4667 14.2219V13.7981C15.4667 13.2463 15.8898 12.7984 16.4112 12.7984C16.9325 12.7984 17.3556 13.2463 17.3556 13.7981V14.4379C18.1263 14.7817 18.7534 15.4135 19.1009 16.2532C19.3125 16.7571 19.0934 17.3489 18.6098 17.5648C18.1338 17.7887 17.5747 17.5488 17.3707 17.045C17.1289 16.4532 16.5547 16.0853 15.9427 16.1493C15.3761 16.2133 15.2476 16.7011 15.2174 16.8451C15.1872 16.997 15.1418 17.5248 15.6556 17.8047V17.8127Z"
        fill="white"
      />
      <path
        d="M19.4995 0.163149C18.8893 -0.252641 17.8514 0.155589 16.9747 1.05521C16.5469 1.49368 15.8947 1.49368 15.4739 1.05521C14.5973 0.148029 13.5663 -0.237522 12.9492 0.163149C10.9083 1.49368 13.6575 6.13541 13.91 6.4H18.5387C18.7911 6.13541 21.4281 1.49368 19.4995 0.163149Z"
        fill="white"
      />
    </svg>
  );
};

export default IconMoney;
