const IconPassword = ({ color }) => {
  return (
    <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2769 6.52465V3.51562C12.2769 1.57711 10.7241 0 8.81538 0H6.18462C4.27589 0 2.72308 1.57711 2.72308 3.51562V6.52465C1.07595 7.17107 0 8.77781 0 10.5577V13.6766C0 16.065 1.90897 18 4.25689 18H10.7431C13.1011 18 15 16.0543 15 13.6766V10.5577C15 8.78171 13.9276 7.17244 12.2769 6.52465ZM4.1077 3.51562C4.1077 2.35252 5.0394 1.40625 6.18462 1.40625H8.81538C9.9606 1.40625 10.8923 2.35252 10.8923 3.51562V6.23703C10.789 6.23338 4.23397 6.23257 4.1077 6.23703V3.51562ZM13.6154 13.6766C13.6154 15.2696 12.3527 16.5938 10.7431 16.5938H4.25689C2.64274 16.5938 1.38461 15.2645 1.38461 13.6766V10.5577C1.38461 8.96737 2.64478 7.64061 4.25689 7.64061H10.7431C12.3656 7.64061 13.6154 8.97887 13.6154 10.5577V13.6766ZM7.5 9.32811C6.36749 9.32811 5.44617 10.2639 5.44617 11.414C5.44617 12.3176 6.01483 13.0888 6.80769 13.378V14.1797C6.80769 14.568 7.11764 14.8828 7.5 14.8828C7.88236 14.8828 8.19231 14.568 8.19231 14.1797V13.378C8.98517 13.0888 9.55383 12.3176 9.55383 11.414C9.55383 10.2639 8.63251 9.32811 7.5 9.32811ZM7.5 12.0938C7.131 12.0938 6.83078 11.7888 6.83078 11.4141C6.83078 11.0393 7.131 10.7344 7.5 10.7344C7.869 10.7344 8.16922 11.0393 8.16922 11.4141C8.16922 11.7888 7.869 12.0938 7.5 12.0938Z"
        fill={color}
      />
    </svg>
  );
};

export default IconPassword;
