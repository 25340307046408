const IconCopy = ({ width = 18, height = 18, color = '#939BB1' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2040_5867)">
        <path
          d="M15.2516 2.76634C15.2268 1.23676 13.9749 0 12.4394 0H2.8125C1.26169 0 0 1.26169 0 2.8125V12.4219C0 13.9513 1.22702 15.1995 2.74841 15.2336C2.77316 16.7632 4.02514 18 5.56056 18H15.1875C16.7383 18 18 16.7383 18 15.1875V5.57814C18 4.0487 16.773 2.80048 15.2516 2.76634ZM3.45118 13.8281H2.8125C2.03709 13.8281 1.40625 13.1973 1.40625 12.4219V2.8125C1.40625 2.03709 2.03709 1.40625 2.8125 1.40625H12.4394C13.2148 1.40625 13.8457 2.03709 13.8457 2.8125V12.4219C13.8457 13.1973 13.2148 13.8281 12.4394 13.8281H3.45118ZM16.5938 15.1875C16.5938 15.9629 15.9629 16.5938 15.1875 16.5938H5.56056C4.80083 16.5938 4.17987 15.9882 4.15508 15.2344H12.4395C13.9903 15.2344 15.252 13.9727 15.252 12.4219V4.17333C15.9976 4.20708 16.5938 4.82428 16.5938 5.5781V15.1875H16.5938Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2040_5867">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconCopy;
