const IconBonus = ({ isActive, color }) => {
  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.86715 4.58048C4.98236 4.37051 4.00135 4.18066 3.05317 3.89383C2.57211 3.74835 2.09914 3.48394 1.71024 3.17378C0.639767 2.31923 0.878949 0.863573 2.15264 0.316444C3.1224 -0.100307 4.13398 -0.0499859 5.14106 0.150842C6.50692 0.423491 7.73295 0.955982 8.59077 2.05664C8.84569 2.38373 9.01383 2.77212 9.20761 3.10882C9.38474 2.79225 9.5439 2.4153 9.78488 2.09416C10.5627 1.05845 11.6898 0.526879 12.9468 0.217632C13.8847 -0.0133887 14.8356 -0.0838384 15.7883 0.149469C16.6802 0.368138 17.2589 0.918012 17.3438 1.67603C17.4391 2.526 16.9576 3.09235 16.2441 3.50818C15.4142 3.99218 14.4822 4.20079 13.5385 4.36639C13.1775 4.42998 12.8129 4.47389 12.4505 4.55853H18.4004V9.69816H9.75296V4.6976H8.66495V9.69861H0V4.58048H5.86715ZM7.64708 3.30919C7.67765 3.27442 7.70867 3.23965 7.73924 3.20488C7.48837 2.93086 7.26852 2.62161 6.97989 2.39014C6.26549 1.81602 5.40632 1.53239 4.49141 1.40567C3.90559 1.3247 3.31573 1.3064 2.75554 1.53742C2.42194 1.67512 2.35765 1.92489 2.62516 2.15454C2.83377 2.33341 3.08913 2.48438 3.35079 2.58273C4.73014 3.10287 6.18906 3.20946 7.64708 3.30919ZM10.667 3.21403C10.6935 3.25063 10.72 3.28769 10.7461 3.32428C11.4511 3.25429 12.1596 3.20626 12.8601 3.10836C13.7368 2.98576 14.6117 2.83068 15.4066 2.42902C15.6691 2.29636 16.0238 2.12023 15.898 1.80778C15.8215 1.61839 15.4646 1.45233 15.2128 1.41894C13.7979 1.23092 12.5121 1.57814 11.3922 2.41667C11.1071 2.63031 10.9062 2.9455 10.6665 3.21403H10.667Z"
        fill={isActive ? '#fff' : color}
      />
      <path
        d="M0.833008 10.833H8.33316V19.9999H1.83301C1.28072 19.9999 0.833008 19.5521 0.833008 18.9999V10.833Z"
        fill={isActive ? '#fff' : color}
      />
      <path
        d="M17.5 10.833H9.99985V19.9999H16.5C17.0523 19.9999 17.5 19.5521 17.5 18.9999V10.833Z"
        fill={isActive ? '#fff' : color}
      />
    </svg>
  );
};

export default IconBonus;
