import Game from 'component/mobile/game/Game';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setLocalStorage } from 'common/helpers/localStorage';
import { toggleNativeOrientation } from 'common/helpers/helper';

export const GameContext = createContext();

export default function GameProvider({ children }) {
  let navigate = useNavigate();
  const [gameUrl, setGameUrl] = useState(null);
  const [navigateUrl, setNavigateUrl] = useState(null);
  const [gameKey, setGameKey] = useState(1);

  useEffect(() => {
    if (gameUrl) {
      setGameKey((k) => k + 1);
      setLocalStorage('refetch', true);
    }
  }, [gameUrl]);

  return (
    <GameContext.Provider
      value={{
        gameUrl: gameUrl,
        setGameUrl: setGameUrl,
        setNavigateUrl: setNavigateUrl,
      }}
    >
      {children}
      <Game
        gameKey={gameKey}
        gameUrl={gameUrl}
        handleCloseGame={() => {
          if (navigateUrl != null) navigate(navigateUrl);
          setNavigateUrl(null);
          setGameUrl(null);
          toggleNativeOrientation(false);
        }}
        openGame={gameUrl !== null}
      />
    </GameContext.Provider>
  );
}

export const useGameUrl = () => useContext(GameContext);
