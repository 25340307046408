import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';

//common
import {
  isUserLoggedIn,
  getRefreshToken,
  setLocalStorage,
  getAccessToken,
  getLocalStorage,
} from 'common/helpers/localStorage';

//service
import {
  useGetCurrentUser,
  useUserSessionActive,
  useUserPing,
  useUpdateMetaEvt,
} from 'services/accountService';
import { useGetGameCacheVersion } from 'services/gameService';
import { useGetBannerListVersion } from 'services/bannerService';
import { useGetPopupBannerListVersion } from 'services/bannerService';
import { STORAGE } from 'common/constants/keys';

//component
import useInterval from 'services/hook/useInterval';
import { useStore } from 'store/StateProvider';
import { silenceError } from 'common/helpers/helper';
import { useSignalR } from 'services/hook/useSignalR';
import { postMetaPurchaseEvent } from 'common/helpers/metaPixel';
import { recordAjustDeposit } from 'common/helpers/adjust';
import { useGetDeposit } from 'services/walletService';
import { firstDepositArrivalEvent } from 'common/helpers/appsFlyer';
import { ssGetUID, ssSetUID } from 'common/helpers/sessionStorage';
import { hashInBase64, filterNumbers } from 'common/helpers/helper';

export default function UserProvider({ children }) {
  const { enqueueSnackbar } = useSnackbar();
  const isLoggedIn = isUserLoggedIn();
  const [store] = useStore();
  const accessToken = getAccessToken(STORAGE.AccessToken);
  const refreshToken = store.user?.refreshToken || getRefreshToken();
  // const [focus, setFocus] = useState(false);
  const [{ data: uData, error }, execute] = useGetCurrentUser(true);
  const [{ data, loading }, refetch] = useUserSessionActive(store?.user?.id);
  const [, ping] = useUserPing();
  const [{ data: gData }, fetch] = useGetGameCacheVersion();
  const [, updateMetaEvt] = useUpdateMetaEvt();
  const [{ data: dData }, getDeposit] = useGetDeposit();
  const { startConnection, stopConnection } = useSignalR();
  const [{ data: bData }, bannerFetch] = useGetBannerListVersion();
  const [{ data: pbData }, popupBannerFetch] = useGetPopupBannerListVersion();
  const uid = ssGetUID();
  const tawkAttributeRef = getLocalStorage(STORAGE.UpdatedAtrributeToTawk);
  // Get user data callback
  useEffect(() => {
    if (uData) {
      ssSetUID(uData.id);
      const { firstDepositId, firstDepositAmt, isMetaEventSent } = uData;
      if (firstDepositId && firstDepositAmt > 0 && isMetaEventSent === 0) {
        silenceError(getDeposit({ data: { DepositId: firstDepositId } }));
      }
    }
  }, [uData, getDeposit]);

  // Get Deposit callback
  useEffect(() => {
    if (dData) {
      const { deposit } = dData;
      const successPost = postMetaPurchaseEvent(deposit.id, deposit.amount, deposit.updated);
      firstDepositArrivalEvent(deposit.amount);
      recordAjustDeposit(deposit.id, deposit.amount, deposit.updated);
      if (successPost) silenceError(updateMetaEvt());
    }
  }, [dData, updateMetaEvt]);

  useEffect(() => {
    silenceError(fetch());
    // fetch();
  }, [fetch]);

  useEffect(() => {
    if (gData) {
      setLocalStorage(STORAGE.GameListVersion, gData);
    }
  }, [gData]);

  useEffect(() => {
    if (isLoggedIn && typeof refreshToken !== 'undefined') {
      const token = refreshToken;
      silenceError(
        execute({
          data: { Token: token },
        }),
      );
      // window.addEventListener('focus', () => setFocus(true), false);
      // window.addEventListener('blur', () => setFocus(false), false);
    }
  }, [isLoggedIn, refreshToken, execute]);

  useInterval(() => {
    fetch();
    bannerFetch();
    popupBannerFetch();
    if (isLoggedIn && store.user) {
      silenceError(refetch());
      const { isMetaEventSent, refreshToken } = store.user;
      if (isMetaEventSent === 0) {
        silenceError(
          execute({
            data: { Token: refreshToken },
          }),
        );
      }
    }
  }, 60000);

  useEffect(() => {
    if (loading) return;
    if (data && data.active) ping();
  }, [loading, data, ping]);

  useEffect(() => {
    if (gData >= 0) {
      setLocalStorage(STORAGE.GameListVersion, gData);
    }
  }, [gData]);

  useEffect(() => {
    if (error) {
      // && !error.includes('Refresh Token not found')
      enqueueSnackbar(error, {
        variant: 'error',
        autoHideDuration: 2500,
      });
    }
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (accessToken) startConnection(accessToken);
    return () => stopConnection();
  }, [accessToken, uid]);

  useEffect(() => {
    const loadTawkScript = () => {
      (function () {
        var s1 = document.createElement('script'),
          s0 = document.getElementsByTagName('script')[0];
        s1.async = true;
        s1.src = `https://embed.tawk.to/${window.TAWK_PROPERTY_ID}/${window.TAWK_WIDGET_ID}`;
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
      })();
    };
    loadTawkScript();
  }, []);

  useEffect(() => {
    try {
      if (store?.user && !tawkAttributeRef) {
        const hash = hashInBase64(store.user.email, window.TAWK_APIKEY);
        window.Tawk_API.setAttributes({
          name: store.user.loginName ?? '',
          email: store.user.email ?? '',
          phone: filterNumbers(store.user.phone) ?? '',
          hash: hash,
        });
        setLocalStorage(STORAGE.UpdatedAtrributeToTawk, true);
      }
    } catch (error) {
      console.error('Tawk update user information error' + error);
    }
  }, [store?.user]);

  useEffect(() => {
    silenceError(bannerFetch());
  }, [bannerFetch]);

  useEffect(() => {
    if (bData) {
      setLocalStorage(STORAGE.BannerListVersion, bData);
    }
  }, [bData]);

  useEffect(() => {
    silenceError(popupBannerFetch());
  }, [popupBannerFetch]);

  useEffect(() => {
    if (pbData) {
      setLocalStorage(STORAGE.PopupBannerListVersion, pbData);
    }
  }, [pbData]);
  
  return (
    <>
      {/* {isLoggedIn && <IdleLogoutTimer warningMin={1} timeoutMin={15} />} */}
      {children}
    </>
  );
}
