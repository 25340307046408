import { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import styles from './LoadingProvider.module.css';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    color: '#fff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none !important',
  },
}));

export const LoadingContext = createContext();

export default function LoadingProvider({ children }) {
  const classes = useStyles();
  // const [loadingData, setLoadingData] = useState([]);

  const location = useLocation();
  const [onLoading, setOnLoading] = useState(false);

  const handleChildPageLoad = () => {
    setTimeout(() => {
      setOnLoading(false);
    }, 500);
  };
  useEffect(() => {
    setOnLoading(true);
    handleChildPageLoad();
  }, [location]);

  const isLoading = useCallback((data, state = false, animation = null) => {
    if (state) {
      // setLoadingData([data]);
      setOnLoading(true);
    } else {
      // setLoadingData((oldArray) => oldArray.filter((x) => x !== data));
      if (!!data) handleChildPageLoad();
    }
  }, []);

  const value = { isLoading };

  return (
    <LoadingContext.Provider value={value}>
      <Modal
        open={onLoading}
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
        }}
      >
        <div className={classes.container}>
          <div className={styles.loading}></div>
        </div>
      </Modal>
      {children}
    </LoadingContext.Provider>
  );
}

export const useLoading = () => useContext(LoadingContext);
