const IconHome = () => {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.6678 9.77007L12.57 0.213104C12.2428 -0.0710348 11.77 -0.0710348 11.4402 0.213104L0.33217 9.77007C-0.322244 10.3307 0.0553822 11.4494 0.898095 11.4494H2.71729V21.2307C2.71729 21.6556 3.04347 22 3.44579 22H8.40741C8.80973 22 9.13591 21.6556 9.13591 21.2307V15.1856C9.13591 13.5155 10.418 12.1611 12 12.1611C13.5815 12.1611 14.8641 13.5149 14.8641 15.1856V21.2307C14.8641 21.6556 15.1903 22 15.5926 22H20.5038C20.9061 22 21.2323 21.6556 21.2323 21.2307V11.4494H23.1019C23.9446 11.4494 24.3222 10.3307 23.6678 9.77007Z"
        fill="white"
      />
    </svg>
  );
};

export default IconHome;
