import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

const myHistoryContext = createContext();

export default function HistoryProvider({ children }) {
  const [myHistory, setMyHistory] = useState([]);

  const push = useCallback((location) => {
    setMyHistory((history) => [...history, location]);
  }, []);

  return (
    <myHistoryContext.Provider value={{ myHistory, push }}>{children}</myHistoryContext.Provider>
  );
}

export const useMyHistory = () => useContext(myHistoryContext);
