import { ACTIONS } from '../constants/actions';

export default function commonReducer(common, { type, payload = null }) {
  switch (type) {
    case ACTIONS.COMMON.AFFILIATESNACKBAR:
      return { ...common, isAffiliateSnackBar: payload };
    case ACTIONS.COMMON.WELCOMESNACKBAR:
      return { ...common, isWelcomeSnackBar: payload };
    case ACTIONS.COMMON.COMMON_SET:
      return {
        ...common,
        ...payload,
      };
    case ACTIONS.COMMON.COMMON_CLEAR:
      return undefined;
    default:
      return common;
  }
}
