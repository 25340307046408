import { getLocalStorage } from './localStorage';

export const generatePixelNode = (pixelId) => {
  const textNode = `!(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  window.fbq('init', ${pixelId});
  window.fbq('track', 'PageView');`; //set autoConfig false to disable sending SubscribedButtonClick event to meta automatically
  return textNode;
};

export const loadPixelScript = (pixelId) => {
  removeJS(); //prevent rendering duplicated pixel account
  const existingScript = document.getElementById('metaPixel');
  if (!existingScript) {
    const script = document.createElement('script');
    script.id = 'metaPixel';
    const myNode = generatePixelNode(pixelId);
    script.appendChild(document.createTextNode(myNode));

    const noScript = document.createElement('noscript');
    const image = document.createElement('img');
    image.src = `https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`;
    image.style.display = 'none';
    image.width = '1';
    image.height = '1';
    noScript.appendChild(image);

    document.head.appendChild(script);
    document.head.appendChild(noScript);
  }
};

export const postMetaRegisterEvent = (eventName, memberReferralCode) => {
  if (typeof window !== 'undefined' && window.fbq != null) {
    const inviteCode_API = getLocalStorage('MemberReferralCode_API');
    const data = {
      content_name: eventName,
      currency: window.WEB_CURRENCY,
      status: 'success',
      value: inviteCode_API !== '' ? inviteCode_API : memberReferralCode,
    };
    const pixelId = getLocalStorage('PIXELID');
    if (typeof pixelId !== 'undefined') {
      window.fbq('trackSingle', pixelId, 'CompleteRegistration', data);
    } else {
      window.fbq('track', 'CompleteRegistration', data);
    }
  }
};

export const postMetaPurchaseEvent = (depositId, depositAmt, approvedAt) => {
  try {
    if (depositId && depositAmt) {
      const data = {
        value: depositAmt,
        currency: window.WEB_CURRENCY,
        content_ids: depositId,
        content_name: approvedAt,
      };
      const pixelId = getLocalStorage('PIXELID');
      if (typeof pixelId !== 'undefined') {
        window.fbq('trackSingle', pixelId, 'Purchase', data);
      } else {
        window.fbq('track', 'Purchase', data);
      }
      return true;
    }
  } catch (error) {
    console.log(error);
  }
  return false;
};

export const postMetaViewEvent = () => {
  if (typeof window.fbq === 'function') {
    const pixelId = getLocalStorage('PIXELID');
    if (typeof pixelId !== 'undefined') {
      window.fbq('trackSingle', pixelId, 'ViewContent');
    } else {
      window.fbq('track', 'ViewContent');
    }
  } else {
    console.log('window.fbq not a function');
    setTimeout(() => postMetaViewEvent(), 800);
  }
};

export const removeJS = () => {
  const tags = Array.from(document.querySelectorAll('script'));
  const script = tags.find(
    (s) => s.src.indexOf('https://connect.facebook.net/signals/config') !== -1,
  );
  if (typeof script !== 'undefined') {
    script.parentNode.removeChild(script);
  }
};
