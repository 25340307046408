import React, { createContext, useContext, useReducer } from 'react';

const AppContext = createContext();

const StateProvider = ({ reducer, initialState, children }) => (
    <AppContext.Provider value={useReducer(reducer, initialState)}>{children}</AppContext.Provider>
);

const useStore = () => useContext(AppContext);

const useDispatch = () => useStore()[1];

export { StateProvider, useStore, useDispatch, AppContext };
