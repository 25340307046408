import React from 'react';
import { Modal } from '@mui/material';

import styles from './CommonPrompt.module.css';
import SubmitButton from 'component/shared/buttons/SubmitButton';
import StyledTextField from 'component/shared/styledTextField/StyledTextField';

const CommonPrompt = (props) => {
  const {
    open,
    cancelEvent = (e) => {
      e.preventDefault();
      console.log('cancelEvent undefined');
    },
    submitEvent = (e) => {
      e.preventDefault();
      console.log('submitEvent undefined');
    },
    title = undefined,
    message = undefined,
    textbox = undefined,
    cancelText = 'Cancel',
    submitText = 'Submit',
    titleImage = undefined,
  } = props;

  return (
    <Modal open={open}>
      <div className={styles.modalContainer}>
        {typeof title !== 'undefined' && (
          <h2 className={styles.title}>
            {titleImage !== undefined && (
              <img
                src={titleImage}
                alt="Logo"
                className={styles.titleImage}
                width={40}
                height={40}
              />
            )}
            {title}
          </h2>
        )}
        {typeof message !== 'undefined' && <div className={styles.message}>{message}</div>}
        {typeof textbox !== 'undefined' && textbox}

        <div className={styles.buttonContainer}>
          <SubmitButton
            value={cancelText}
            type="button"
            handleClick={cancelEvent}
            externalClassName={styles.cancelBtn}
            isPrimary={false}
          />

          <SubmitButton
            value={submitText}
            type="button"
            handleClick={submitEvent}
            externalClassName={styles.submitBtn}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CommonPrompt;
