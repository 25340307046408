import React, { useCallback, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Outlet, useLocation } from 'react-router-dom';
import Topbar from './Topbar/Topbar';
import TopbarSecondary from './Topbar/TopbarSecondary';
import Footer from './Footer/Footer';
import styles from './Layout.module.css';

import { setLocalStorage, getLocalStorage, isUserLoggedIn } from 'common/helpers/localStorage';
import WelcomeBonus from './Topbar/snackbar/WelcomeBonus';
import AffiliateInvite from './Topbar/snackbar/AffiliateInvite';
import { useDispatch, useStore } from 'store/StateProvider';
import { setAffiliateSnackBar, setWelcomeSnackBar } from 'common/reducer-actions/commonActions';
import { ROUTES } from 'common/constants/routing';

const Layout = ({
  isPrimary = false,
  showCloseButton,
  handleCloseGame = null,
  dynamicScreen,
  hasLeftBack = false,
  event = false,
  noPrimaryLogo,
  logoLeft = false,
}) => {
  const intl = useIntl();
  const [store] = useStore();
  // const navigate = useNavigate();
  const location = useLocation();
  const [isSnackbar, setIsSnackbar] = useState(true);
  //const [{ data: spinWheelData }, execute] = useGetRaffleData(true, false);
  const isLoggedIn = isUserLoggedIn();
  const dispatch = useDispatch();
  const isWelcomeSnackBar = store.common?.isWelcomeSnackBar;
  const isAffiliateSnackBar = store.common?.isAffiliateSnackBar;

  /*useEffect(() => {
    const spin = getLocalStorage('spin');
    if (spin) {
      execute();
    } else {
      setLocalStorage('spin', false);
    }
  }, [execute]);*/

  /*useEffect(() => {
    if (spinWheelData) {
      if (spinWheelData.spinChances !== 0) {
        navigate(ROUTES.USER.LUCKY_WHEEL.ROOT, { state: { isManualTrigger: false } });
        setLocalStorage('spin', false);
      } else {
        setLocalStorage('spin', false);
      }
    }
  }, [spinWheelData, navigate]);*/

  useEffect(() => {
    if (!isLoggedIn) {
      const welcomeSnack = getLocalStorage('welcomeSnackbar');
      setWelcomeSnackBar(dispatch, welcomeSnack);
    } else {
      const affiliateSnackbar = getLocalStorage('affiliateSnackbar');
      setAffiliateSnackBar(dispatch, affiliateSnackbar);
    }
  }, []);

  // const handleCloseSnackbar = useCallback(
  //   (prop, value) => {
  //     setIsSnackbar(value);
  //     if (prop === 'affiliateInvite') {
  //       setLocalStorage('affiliateSnackbar', false);
  //       setAffiliateSnackBar(dispatch, false);
  //     } else if (prop === 'welcomeBonusSnackbar') {
  //       setLocalStorage('welcomeSnackbar', false);
  //       setWelcomeSnackBar(dispatch, false);
  //     }
  //     // window.scrollTo(0, 0);
  //   },
  //   [dispatch],
  // );

  return (
    <div lang={intl.locale} className={styles.container}>
      <div
        className={`${styles.contentContainer} ${
          location.pathname === ROUTES.BONUS.ROOT ? styles.bonusContentNonImgBg : ''
        }`}
        id="layoutContent"
      >
        {!handleCloseGame && !event && <WelcomeBonus />}
        {!handleCloseGame && !event && <AffiliateInvite />}
        {isPrimary ? (
          <Topbar
            isWelcomeSnackBar={isWelcomeSnackBar}
            isAffiliateSnackBar={isAffiliateSnackBar}
            showCloseButton={showCloseButton}
            handleCloseGame={handleCloseGame}
            hasLeftBack={hasLeftBack}
          />
        ) : (
          <TopbarSecondary
            isWelcomeSnackBar={isWelcomeSnackBar}
            isAffiliateSnackBar={isAffiliateSnackBar}
            noPrimaryLogo={noPrimaryLogo}
            logoLeft={logoLeft}
            showCloseButton={showCloseButton}
            event={event}
          />
        )}
        <div className={`${styles.wrapper} ${dynamicScreen ? styles.wrapper3 : ''}`}>
          <Outlet />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
