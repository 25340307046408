import { useEffect, useRef } from 'react';

export default function useInterval(callback, delay, runNow = false) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let handleId;

    const tick = () => {
      savedCallback.current();
      schedule();
    };

    const schedule = () => {
      clearTimeout(handleId);
      handleId = setTimeout(tick, delay);
    };

    if (!delay && delay !== 0) {
      return;
    }

    if (runNow) {
      tick();
    } else {
      schedule();
    }
    return () => clearTimeout(handleId);
  }, [delay, runNow]);
}
