import React, { useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
// import { useStore } from 'store/StateProvider';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import MuiAccordion from '@mui/material/Accordion';
import { styled, useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { AiOutlineClose } from '@react-icons/all-files/ai/AiOutlineClose';
import { ROUTES } from 'common/constants/routing';
import { isUserLoggedIn } from 'common/helpers/localStorage';
import { MobileImages } from 'common/constants/image-resource';
import IconMenu from 'component/mobile/common/icon/IconMenu';
import { Context } from 'component/shared/provider/IntlWrapper';
import useGetSidebarGame from 'services/hook/useGetSidebarGame';
import { BANNERENUM, SIDEBARENUM, SIDEBARGAMEENUM } from './SideberEnum';
import styles from './Sidebar.module.css';
import IconAndroid from 'component/mobile/common/icon/IconAndroid';
import IconIOS from 'component/mobile/common/icon/IconIOS';
import { isIOS } from 'common/helpers/helper';
import { ANDROID_IOS_TAB } from 'common/constants/tabs';
import { handlePromise } from 'common/helpers/helper';
import useFetchReferCode from 'services/hook/useFetchReferCode';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: 'var(--mui-palette-bg-primary)',
  border: `0px solid ${theme.palette.divider}`,
  borderRadius: 4,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  // backgroundColor: 'var(--mui-palette-bg-secondary)',
  borderRadius: 4,
  marginTop: 5,
  alignItems: 'center',
}));

const useStyle = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  sidebarItem: {
    padding: '3px 5px',
    borderRadius: 4,
    alignItems: 'center',
    display: 'flex',
    gap: 5,
    '&:hover, &:focus': {
      backgroundColor: 'var(--mui-palette-bg-tertiary)',
      borderRadius: 57,
    },
  },
  drawerRoot: {
    zIndex: theme.zIndex.modal,
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: 700,
      margin: 'auto',
      '& .MuiBox-root': {
        padding: '10px 8px 20px',
      },
    },
    '& .MuiAccordionSummary-content': {
      gap: 5,
      margin: 0,
      alignItems: 'center',
    },
    '& .MuiAccordionSummary-root': {
      padding: '0px 5px !important',
      margin: '0px',
      height: 38,
      minHeight: 'unset',
      '& .MuiAccordionSummary-expandIconWrapper': {
        color: 'var(--mui-palette-text-primary)',
      },
      '& img': {
        filter: 'grayscale(1) brightness(2)',
      },
    },
    '& .MuiAccordionSummary-root:focus': {
      backgroundColor: 'var(--mui-palette-bg-tertiary)',
      color: 'var(--mui-palette-text-primary) !important',
      height: 38,
      borderRadius: 57,
      '& .MuiAccordionSummary-expandIconWrapper': {
        color: 'var(--mui-palette-text-primary)',
      },
      '& img': {
        filter: 'grayscale(1) brightness(2)',
      },
    },
    '& .MuiAccordionSummary-root:hover': {
      backgroundColor: 'var(--mui-palette-bg-tertiary)',
      height: 38,
      borderRadius: 57,
    },
    '& .MuiAccordionDetails-root': {
      paddingLeft: 47,
      display: 'flex',
      padding: 0,
      height: 38,
      marginTop: 5,
    },
    '& .MuiAccordionDetails-root:hover': {
      backgroundColor: 'var(--mui-palette-bg-tertiary)',
      height: 38,
      marginTop: 5,
      borderRadius: 57,
      display: 'flex',
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '8px 16px 2px',
}));

const Sidebar = () => {
  const isLoggedIn = isUserLoggedIn();
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyle();
  const navigate = useNavigate();
  const langContext = useContext(Context);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [checkGameLength, redirectToGame] = useGetSidebarGame();
  const currentLocation = useLocation();
  // const [store] = useStore();
  // const { user } = store;
  const [, , , appView] = useFetchReferCode();

  // const spinChances = user?.spinChances ? user?.spinChances : 0;
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenDrawer(open);
  };

  const gotoPage = (item, subItem) => {
    setOpenDrawer(false);

    // Handle affiliate redirection
    // if(subItem?.path === ROUTES.AFFILIATE.ROOT) {
    //   navigate(ROUTES.AFFILIATE[isLoggedIn ? "ROOT" : "PARTNER"]);
    //   return;
    // }

    if (!isLoggedIn && subItem?.loginCheck) {
      navigate(ROUTES.ACCOUNT.LOGIN);
    } else {
      if (subItem?.pathParam) {
        //for category game navigation
        subItem.path = subItem?.path.replace(':id', subItem?.name);
      }

      navigate({
        pathname: item.path ? item.path : subItem?.path,
        search: subItem?.searchParam ? `?${createSearchParams(subItem.searchParam)}` : null,
      });
    }
  };

  const gotoHome = () => {
    setOpenDrawer(false);
    navigate(ROUTES.HOME);
  };

  const handleGameCatRedirect = async (item) => {
    if (item.search) {
      gotoPage({}, item);
    } else {
      const [data] = await handlePromise(checkGameLength(item.name));

      if (data[0] > 0) {
        setOpenDrawer(false);
        redirectToGame(data[0], data[1]);
      } else {
        gotoPage({}, item);
      }
    }
  };

  const openCricket = () => {
    setOpenDrawer(false);
    navigate({
      pathname: ROUTES.TSBSPORTS.ROOT,
      search: `?${createSearchParams('catType=cricket')}`,
    });
  };

  // const goToLuckyWheel = () => {
  //   setOpenDrawer(false);
  //   navigate(ROUTES.USER.LUCKY_WHEEL.ROOT);
  // };

  const gotoInstallationPage = (selectedTab) => {
    const defaultTab = isIOS() ? ANDROID_IOS_TAB[1].value : ANDROID_IOS_TAB[0].value;
    const tabToNavigate = selectedTab || defaultTab;
    navigate(ROUTES.APP_INSTALATION.ROOT, { state: { selectedTab: tabToNavigate } });
  };

  return (
    <div className={`${styles.navLink} ${openDrawer ? 'active' : ''}`}>
      <div onClick={toggleDrawer(true)} className={styles.tab}>
        <IconMenu isActive={true} color={theme.palette.footerIcon} /> {/* isActive = openDrawer */}
        {/* <div>{intl.formatMessage({ id: 'footer.menu' })}</div> */}
      </div>
      <Drawer anchor="left" open={openDrawer} className={classes.drawerRoot}>
        <Box role="presentation">
          <div className={styles.drawer}>
            <div className={styles.headerContainer}>
              {/* <ArrowBackIosRoundedIcon
                color="primary"
                sx={{ fontSize: 22 }}
                onClick={toggleDrawer(false)}
              />
              <ThemeSwitch /> */}
              <img
                src={MobileImages.logoPrimary}
                width={85}
                height={30}
                alt="App Logo"
                className={styles.logo}
                onClick={() => gotoHome()}
              />
              <div className={styles.closeContainer}>
                <div onClick={toggleDrawer(false)} className={styles.closeButton}>
                  <AiOutlineClose color={`#939BB1`} size={21} />
                </div>
              </div>
            </div>
            {/* <div className={styles.spinDiv}>
              <img
                className={styles.lucky_wheel_img}
                width={561}
                height={121}
                src={MobileImages.home.sidebarBanner}
                alt="Lucky Wheel"
                // onClick={() =>
                //   currentLocation.pathname !== ROUTES.USER.LUCKY_WHEEL.ROOT
                //     ? goToLuckyWheel()
                //     : setOpenDrawer(false)
                // }
              />
              <div className={styles.spinCount}>
                <div className={styles.spinCountBg}>
                  <img
                    src={MobileImages.home.spinCountBg}
                    width={22}
                    height={22}
                    alt="Lucky Wheel"
                  />
                  <div className={styles.spinCountTxtDiv}>
                    <span className={styles.spinCountTxt}>{spinChances}</span>
                  </div>
                </div>
              </div>
            </div> */}
            <div className={styles.gameBanner}>
              <img
                className={styles.width50}
                width={330}
                height={134}
                src={MobileImages.home.cricketBanner}
                alt="Cricket"
                onClick={openCricket}
              />
              <img
                className={styles.width50}
                width={330}
                height={118}
                src={MobileImages.home.aviatorBanner}
                alt="Aviator"
                onClick={() => {
                  handleGameCatRedirect(BANNERENUM.aviator);
                }}
              />
            </div>
            {/* <div className={styles.vipContainer}>
              <img
                src={MobileImages.gold}
                alt="Gold"
                width={50}
                height={50}
                className={styles.vipIcon}
              />
            </div>       */}
            {Object.values(SIDEBARENUM).map((item, i) => {
              return (
                <Accordion
                  className={styles.accordion}
                  key={i}
                  onClick={
                    item.mainRedirect
                      ? () => {
                          navigate(item.path);
                        }
                      : undefined
                  }
                >
                  <AccordionSummary
                    expandIcon={item.subList ? <ExpandMoreIcon /> : null}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className={styles.iconContainer}>
                      <img
                        className={styles.iconImg}
                        src={item.icon}
                        width={item.width}
                        height={item.height}
                        alt={item.name}
                      />
                    </div>
                    <Typography className={styles.typotitle}>
                      {intl.formatMessage({ id: `sidebar.${item.name}` })}
                    </Typography>
                  </AccordionSummary>
                  {item.subList != null &&
                    item.subList.map((items, i) => {
                      return (
                        <AccordionDetails
                          key={i}
                          onClick={() =>
                            items.gameCatRedirect
                              ? handleGameCatRedirect(items)
                              : gotoPage(item, items)
                          }
                        >
                          <div className={styles.subItem}>
                            <div className={styles.subIconList}>
                              <img
                                className={styles.subIconImg}
                                src={items.icon}
                                alt={items.name}
                              />
                            </div>
                            <div className={styles.typo}>
                              <span> {intl.formatMessage({ id: `sidebar.${items.name}` })}</span>
                            </div>
                          </div>
                        </AccordionDetails>
                      );
                    })}
                </Accordion>
              );
            })}
            {Object.values(SIDEBARGAMEENUM).map((item, key) => {
              return (
                <div
                  className={classes.sidebarItem}
                  key={key}
                  onClick={() => handleGameCatRedirect(item)}
                >
                  <div className={styles.itemImg}>
                    <img
                      src={item.icon}
                      className={styles.subIcon}
                      width={item.width}
                      height={item.height}
                      alt={item.name}
                    />
                  </div>
                  <div className={styles.itemTxt}>
                    <span className={styles.gameItemName}>
                      {intl.formatMessage({ id: `sidebar.${item.name}` })}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </Box>
        <div className={styles.sidebarBottom}>
          {!appView && (
            <div
              className={styles.downloadContainer}
              onClick={() =>
                currentLocation.pathname !== ROUTES.APP_INSTALATION.ROOT
                  ? gotoInstallationPage()
                  : setOpenDrawer(false)
              }
            >
              {/* <img
                className={styles.downloadBgImg}
                src={MobileImages.home.downloadBg}
                alt="Download"
                width={516}
                height={102}
              /> */}
              <div className={styles.downloadContent}>
                <div className={styles.downloadImg}>
                  <IconAndroid isActive={true} />
                </div>
                <span className={styles.downloadTxt}>
                  {intl.formatMessage({ id: 'sidebar.download_app' })}
                </span>
                <div className={styles.downloadImg}>
                  <IconIOS isActive={true} />
                </div>
              </div>
            </div>
          )}
          <div className={styles.liveSupportLangContext}>
            <div
              className={styles.support}
              onClick={() => {
                // setOpenDrawer(false);
                // window.Tawk_API.maximize();
                window.location.href = 'https://direct.lc.chat/18386301/';
              }}
            >
              <img
                src={MobileImages.home.livesupporttIcon}
                width={20}
                height={22}
                alt="Live Support"
              />
              <span className={styles.itemName}>
                {intl.formatMessage({ id: 'sidebar.live_support' })}
              </span>
            </div>
            <langContext.changeLangComponent />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Sidebar;
