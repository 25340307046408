import { STORAGE } from 'common/constants/keys';
import { getLocalStorage } from './localStorage';

export const recordAdjustRegister = () => {
  recordAdjustEvent(window.EVT_CODE_REGISTER || 's7i6h5');
};

export const recordAjustLogin = () => {
  recordAdjustEvent(window.EVT_CODE_LOGIN || 'athq0e');
};

export const recordAjustDeposit = (depositId, depositAmt, approvedAt) => {
  const data = {
    revenue: {
      value: depositAmt,
      currency: window.WEB_CURRENCY,
    },
    callbackParams: {
      content_ids: depositId,
      content_name: approvedAt,
    },
  };
  recordAdjustEvent(window.EVT_CODE_DEPOSIT || 'el3dpn', data);
};

function recordAdjustEvent(eventName, eventParams = null) {
  let fromApp = getLocalStorage(STORAGE.AppView);
  if (fromApp === null || typeof fromApp === 'undefined') {
    return;
  }
  window.ReactNativeWebView?.postMessage(
    JSON.stringify({
      adjustEvent: true,
      eventCode: eventName,
      eventParams: eventParams,
    }),
  );
  // window.jsBridge?.postMessage(eventName, JSON.stringify(eventParams));
}
