import React from 'react';
import { NumericFormat } from 'react-number-format';

const NumFormat = (props) => {
  const { value, className, suffix, prefix, decimalScale, thousandSeparator = true } = props;
  const truncate = (value, precision = 2) => {
    const factor = Math.pow(10, precision);
    return Math.trunc(value * factor) / factor;
  };
  return (
    <NumericFormat
      className={className}
      value={truncate(value)}
      suffix={suffix}
      prefix={prefix}
      displayType={'text'}
      decimalScale={typeof decimalScale === 'undefined' || decimalScale === null ? 2 : decimalScale}
      fixedDecimalScale={true}
      thousandSeparator={thousandSeparator}
    />
  );
};

export default NumFormat;
