import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetGameCategory } from 'services/gameService';
import { isUserLoggedIn } from 'common/helpers/localStorage';
import { useSnackbar } from 'notistack';
import {
  silenceError,
  getClientIpByList,
  handlePromise,
  toggleNativeOrientation,
} from 'common/helpers/helper';
import { useLaunchGame } from 'services/gameService';
import { ROUTES } from 'common/constants/routing';
import { useLoading } from 'component/shared/provider/LoadingProvider';
import { useGameUrl } from 'component/shared/provider/GameProvider';

const useGetSidebarGame = () => {
  const navigate = useNavigate();
  const { isLoading } = useLoading();
  // const [gameCatList, setGameCatList] = useState(null);
  const [lock, setLock] = useState(false);
  const [myGame, setMyGame] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [{ data }, execute] = useGetGameCategory(true);
  const [{ data: lData, loading: lLoading, error: launchError }, launchGame] = useLaunchGame();
  const { setGameUrl } = useGameUrl();

  useEffect(() => {
    // if (data) {
    //   setGameCatList(data);
    // }
    if (launchError) {
      enqueueSnackbar(launchError, {
        variant: 'error',
        autoHideDuration: 2500,
      });
      navigate(ROUTES.HOME);
    }
  }, [data, launchError, enqueueSnackbar, navigate]);

  const checkGameLength = async (name) => {
    const [data] = await handlePromise(execute());
    if (data.data !== null && data.data.subgameCategoryType.length > 0) {
      const catTypeList = data.data.subgameCategoryType;
      const cat = catTypeList.filter((i) => i.name.toLowerCase() === name.toLocaleLowerCase());
      let gameId = 0;
      if (cat.length > 0) {
        gameId = cat[0].subgameCategoryId;
      }
      if (data.data.listOfSubGameByCategory[gameId].length === 1) {
        return [gameId, data.data];
      }
    }
    return [0, data.data];
  };
  const submit = useCallback(
    async (game) => {
      let [ipdata] = await handlePromise(getClientIpByList());
      const ipAddress = (ipdata && ipdata[0]) || undefined;
      silenceError(
        launchGame({
          data: {
            gameId: game.gameId.toString(),
            subGameId: game.id,
            ipAddress: ipAddress,
          },
        }),
      );
    },
    [launchGame],
  );

  const redirectToGame = useCallback(
    async (gameId, categoryData) => {
      if (!isUserLoggedIn()) {
        return navigate(ROUTES.ACCOUNT.LOGIN);
      }
      const game = categoryData.listOfSubGameByCategory[gameId][0];
      setMyGame(game);
      if (!lock) {
        setLock(true);
        submit(game);
      }
    },
    [lock, navigate, submit],
  );
  useEffect(() => {
    if (lData && myGame) {
      setLock(false);
      if (lData.url || lData.additionalData) {
        setTimeout(() => {
          // navigate({
          //   pathname: ROUTES.GAME.ROOT,
          //   search: createSearchParams({
          //     gamepath: lData.url,
          //     returnpath: ROUTES.HOME,
          //   }).toString(),
          // });
          setGameUrl(lData);
          toggleNativeOrientation(true);
          return false;
        }, 200);
      }
    }
  }, [lData, myGame, setGameUrl]);

  useEffect(() => {
    isLoading('launchCategoryGame', lLoading);
  }, [lLoading, isLoading]);

  return [checkGameLength, redirectToGame];
};

export default useGetSidebarGame;
