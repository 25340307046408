import React, { memo } from 'react';

import styles from './CommonFrame.module.css';

const CommonFrame = (props) => {
  const {
    titleValue,
    startItem = null,
    EndItem = null,
    containerClass = '',
    titleBorder = true,
    titleClass = '',
    downClass = '',
    deposit = false,
  } = props;
  return (
    <div
      className={`${styles.container} ${
        deposit ? styles.depositMarginTop : styles.marginTop
      } ${containerClass}`}
    >
      <div
        className={`${styles.title} ${deposit ? styles.depTilePadding : styles.tilePadding} ${
          !titleBorder ? styles.withoutBorder : ''
        }`}
      >
        {startItem && <div className={styles.startIcon}>{startItem}</div>}
        <div className={`${titleClass}`}>{titleValue}</div>
        {EndItem && <EndItem />}
      </div>
      <div className={`${deposit ? styles.innerWrapperDep : styles.innerWrapper} ${downClass}`}>
        {props.children}
      </div>
    </div>
  );
};

export default memo(CommonFrame);
