import React from 'react';
import { Modal } from '@mui/material';
import Topbar from '../app/layouts/Topbar/Topbar';
// import AffiliateInvite from '../app/layouts/Topbar/snackbar/AffiliateInvite';
// import { setLocalStorage, isUserLoggedIn } from 'common/helpers/localStorage';

import styles from './Game.module.css';

const Game = (props) => {
  const { gameUrl, handleCloseGame, openGame = false, gameKey } = props;
  // const isLoggedIn = isUserLoggedIn();
  // const [isSnackbar, setIsSnackbar] = useState(true);

  // const handleCloseSnackbar = useCallback((prop, value) => {
  //   setIsSnackbar(value);
  //   setLocalStorage('affiliateSnackbar', false);
  // }, []);

  return (
    <Modal open={openGame} key={gameKey}>
      <div className={styles.wrapper}>
        {/* <div className={styles.header}>
          <div
            onClick={(e) => {
              handleCloseGame(e);
            }}
            className={styles.iconWrapper}
          >
            <ArrowBackIosRoundedIcon color="primary" sx={{ fontSize: 22, color: 'var(--mui-palette-text-secondary)' }} />
          </div>
          <LazyLoadImage
            src={MobileImages.logoPrimary}
            width={105}
            height={30}
            alt="logo"
            className={styles.logo}
            onClick={(e) => {
              handleCloseGame(e);
              navigate(ROUTES.HOME);
            }}
          />
        </div> */}
        {/* <AffiliateInvite
          handleCloseGame={handleCloseGame}
          closeSnackbar={handleCloseSnackbar}
          isLoggedIn={isLoggedIn}
          isSnackbar={isSnackbar}
        /> */}
        <Topbar handleCloseGame={handleCloseGame} />
        {gameUrl && gameUrl.url && (
          <iframe
            src={gameUrl.url}
            title="gamepage"
            className={styles.container}
            allow="autoplay; clipboard-write"
          ></iframe>
        )}
        {gameUrl && gameUrl.additionalData && gameUrl.additionalData.html && (
          <iframe
            srcDoc={gameUrl.additionalData.html}
            title="gamepage"
            className={styles.container}
          ></iframe>
        )}
      </div>
    </Modal>
  );
};

export default Game;
