import { STORAGE } from 'common/constants/keys';
import { getLocalStorage } from './localStorage';

export const recordRegCompleteEvent = () => {
  // method - 0: mobile; 1: desktop
  var eventName = 'af_complete_registration';
  var eventData = {
    af_registration_method: '0',
  };
  var uniUrl = 'uniwebview://action_register?isSuccess=ture';
  recordEvent(eventName, eventData, uniUrl);
};

export const recordLoginEvent = () => {
  var eventName = 'af_login';
  var eventData = {};
  recordEvent(eventName, eventData);
};

export const firstDepositArrivalEvent = (amount) => {
  var eventName = 'firstDepositArrival';
  var eventData = {};
  var uniUrl = `uniwebview://action_rechageFirst?number=${amount}`;
  recordEvent(eventName, eventData, uniUrl);
};

export const recordViewRegisterEvent = () => {
  var eventName = 'af_content_view';
  var eventData = {
    af_content: 'Register',
  };
  var uniUrl = `uniwebview://action_contentView?content=Register`;
  recordEvent(eventName, eventData, uniUrl);
};

const afmapping = { af_complete_registration: 'register' };

function recordEvent(eventName, eventParams, uniUrl = null) {
  let fromUniApp = getLocalStorage(STORAGE.FromUniApp);
  // let appsFlyerUID = getLocalStorage(STORAGE.ThirdPartyAppsflyerId);
  // if (typeof appsFlyerUID === 'undefined') {
  //   appsFlyerUID = window.APPSFLYER_ID;
  // }
  // if (appsFlyerUID === null || typeof appsFlyerUID === 'undefined') {
  //   return;
  // }
  if (typeof window.FROM_INR91RN !== 'undefined') {
    console.log('is from FROM_INR91RN');
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        appsFlyerEvent: true,
        eventName: eventName,
        eventValues: eventParams,
      }),
    );
  } else if (fromUniApp && uniUrl !== null) {
    window.location.href = uniUrl;
  } else {
    eventName = afmapping[eventName] ?? eventName;
    console.log(`is NOT from FROM_INR91RN eventName ${eventName}`);
    window.jsBridge?.postMessage(eventName, JSON.stringify(eventParams));
    // alert(appsFlyerUID);
  }
}
