import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './ProtectedLayout.module.css';

const ProtectedLayout = () => {
  return (
    <section className={styles.container}>
      <Outlet />
    </section>
  );
};

export default ProtectedLayout;
