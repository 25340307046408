import React, { lazy, Suspense } from 'react';

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false',
    );
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};
const NotFoundCom = lazy(() =>
  lazyRetry(() => import('component/mobile/error-page/NotFound/NotFound')),
);
const ErrorPageCom = lazy(() =>
  lazyRetry(() => import('component/mobile/error-page/ErrorPage/ErrorPage')),
);
const FooterCom = lazy(() => lazyRetry(() => import('component/mobile/app/layouts/Footer/Footer')));
const TopbarCom = lazy(() => lazyRetry(() => import('component/mobile/app/layouts/Topbar/Topbar')));
const HomeCom = lazy(() => lazyRetry(() => import('component/mobile/home/Home')));
const LoginCom = lazy(() => lazyRetry(() => import('component/mobile/account/Login/Login')));
const LogoutCom = lazy(() => lazyRetry(() => import('component/mobile/account/Logout/Logout')));
const ForgotPasswordCom = lazy(() =>
  lazyRetry(() => import('component/mobile/account/forgot-password/ForgotPassword')),
);
const ResetPasswordCom = lazy(() =>
  lazyRetry(() => import('component/mobile/account/reset-password/ResetPassword')),
);
const MemberCenterCom = lazy(() => lazyRetry(() => import('component/mobile/user/MemberCenter')));
const RegisterCom = lazy(() =>
  lazyRetry(() => import('component/mobile/account/register/Register')),
);
const MailConfirmationCom = lazy(() =>
  lazyRetry(() => import('component/mobile/account/register/MailConfirmation')),
);
const CreateAccountCom = lazy(() =>
  lazyRetry(() => import('component/mobile/account/register/CreateAccount')),
);
const DepositCom = lazy(() => lazyRetry(() => import('component/mobile/wallet/deposit/Deposit')));
const VerifyCom = lazy(() => lazyRetry(() => import('component/mobile/account/verify/Verify')));
const HomeGameLobbyCom = lazy(() => lazyRetry(() => import('component/mobile/game/HomeGameLobby')));
const TSBSportsCom = lazy(() =>
  lazyRetry(() => import('component/mobile/home/tsbwidget/TSBWidget')),
);
const TransactionCom = lazy(() =>
  lazyRetry(() => import('component/mobile/transaction/Transaction')),
);
const WithdrawalCom = lazy(() =>
  lazyRetry(() => import('component/mobile/wallet/withdrawal/Withdrawal')),
);
const GameCatCom = lazy(() => lazyRetry(() => import('component/mobile/gamecat/GameCat')));

const Affiliate2Com = lazy(() =>
  lazyRetry(() => import('component/mobile/affiliateNew/Affiliate')),
);
const AffiliateDetailsCom = lazy(() =>
  lazyRetry(() => import('component/mobile/affiliateNew/details/Details')),
);
const PartnerCom = lazy(() =>
  lazyRetry(() => import('component/mobile/affiliateNew/partner/Partner')),
);
const BonusCom = lazy(() => lazyRetry(() => import('component/mobile/bonus/Bonus')));
const BonusDetailsCom = lazy(() => lazyRetry(() => import('component/mobile/bonus/BonusDetails')));
const VipCom = lazy(() => lazyRetry(() => import('component/mobile/user/vip/Vip')));
const MyVipCom = lazy(() => lazyRetry(() => import('component/mobile/user/myvip/MyVip')));
const LuckyWheelCom = lazy(() =>
  lazyRetry(() => import('component/mobile/user/luckywheel/LuckyWheel')),
);
const PolicyCom = lazy(() => lazyRetry(() => import('component/mobile/policy/Policy')));
const MaintenanceCom = lazy(() =>
  lazyRetry(() => import('component/mobile/maintenance/Maintenance')),
);
const IntercomCom = lazy(() => lazyRetry(() => import('component/mobile/intercom/Intercom')));
const TermServiceCom = lazy(() =>
  lazyRetry(() => import('component/mobile/termservice/TermService')),
);
const DataDeletionCom = lazy(() =>
  lazyRetry(() => import('component/mobile/datadeletion/DataDeletion')),
);
const UserAgreementCom = lazy(() =>
  lazyRetry(() => import('component/mobile/useragreement/UserAgreement')),
);
const FavouriteCom = lazy(() =>
  lazyRetry(() => import('component/mobile/favourite/FavouriteGame')),
);
const RecentCom = lazy(() => lazyRetry(() => import('component/mobile/recent/RecentGame')));
const AppInstallationCom = lazy(() =>
  lazyRetry(() => import('component/mobile/home/category/HomeAppInstallationGuide')),
);
const ProvidersCom = lazy(() =>
  lazyRetry(() => import('component/mobile/providers/ProvidersPage')),
);

const JiliEventCom = lazy(() => lazyRetry(() => import('component/mobile/event/JiliEvent')));
const UnsubscribeCom = lazy(() =>
  lazyRetry(() => import('component/mobile/account/unsubscribe/Unsubscribe')),
);

const FreeSpinCom = lazy(() => lazyRetry(() => import('component/mobile/freespin/FreeSpin')));
const VoucherCom = lazy(() => lazyRetry(() => import('component/mobile/bonus/Voucher')));
const VaultCom = lazy(() => lazyRetry(() => import('component/mobile/wallet/vault/Vault')));
const ReferralBonusCom = lazy(() =>
  lazyRetry(() => import('component/mobile/referralBonus/Referral')),
);
//component wrap with Suspense
const withSuspense =
  (Component) =>
  ({ ...props }) =>
    (
      <>
        <Suspense fallback={<div></div>}>
          <Component {...props} />
        </Suspense>
      </>
    );

export const BrandRoutes = {
  NotFound: withSuspense(NotFoundCom),
  ErrorPage: withSuspense(ErrorPageCom),
  Footer: withSuspense(FooterCom),
  Topbar: withSuspense(TopbarCom),
  Home: withSuspense(HomeCom),
  Login: withSuspense(LoginCom),
  Logout: withSuspense(LogoutCom),
  Register: withSuspense(RegisterCom),
  Deposit: withSuspense(DepositCom),
  Verify: withSuspense(VerifyCom),
  HomeGameLobby: withSuspense(HomeGameLobbyCom),
  TsbSports: withSuspense(TSBSportsCom),
  Transaction: withSuspense(TransactionCom),
  ForgotPassword: withSuspense(ForgotPasswordCom),
  ResetPassword: withSuspense(ResetPasswordCom),
  MailConfirmation: withSuspense(MailConfirmationCom),
  CreateAccount: withSuspense(CreateAccountCom),
  Withdrawal: withSuspense(WithdrawalCom),
  MemberCenter: withSuspense(MemberCenterCom),
  GameCat: withSuspense(GameCatCom),
  Affiliate2: withSuspense(Affiliate2Com),
  AffiliateDetails: withSuspense(AffiliateDetailsCom),
  Partner: withSuspense(PartnerCom),
  Bonus: withSuspense(BonusCom),
  BonusDetails: withSuspense(BonusDetailsCom),
  Vip: withSuspense(VipCom),
  MyVip: withSuspense(MyVipCom),
  Policy: withSuspense(PolicyCom),
  Maintenance: withSuspense(MaintenanceCom),
  TermService: withSuspense(TermServiceCom),
  DataDeletion: withSuspense(DataDeletionCom),
  UserAgreement: withSuspense(UserAgreementCom),
  Favourite: withSuspense(FavouriteCom),
  Recent: withSuspense(RecentCom),
  Intercom: withSuspense(IntercomCom),
  Installation: withSuspense(AppInstallationCom),
  Providers: withSuspense(ProvidersCom),
  LuckyWheel: withSuspense(LuckyWheelCom),
  JiliEvent: withSuspense(JiliEventCom),
  Unsubscribe: withSuspense(UnsubscribeCom),
  FreeSpin: withSuspense(FreeSpinCom),
  Voucher: withSuspense(VoucherCom),
  Vault: withSuspense(VaultCom),
  ReferralBonus: withSuspense(ReferralBonusCom),
};
