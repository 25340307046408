import { ACTIONS } from '../constants/actions';

export default function referralReducer(referral, { type, payload = null }) {
  switch (type) {
    case ACTIONS.REFERRAL.REFERRAL_SPIN_DETAILS:
      return {
        ...referral,
        ...payload,
      };
      case ACTIONS.REFERRAL.AWARD_SPIN_AMOUNT:
        return {
          ...referral,
          award_spin_amount: payload,
        };
      case ACTIONS.REFERRAL.SHOW_WINNING_AMOUNT:
        return {
          ...referral,
          showWinningAmount: payload,
        };
        case ACTIONS.REFERRAL.REFERRAL_BONUS_WON_AMOUNT:
          return {
            ...referral,
            referralBonusWonAmount: payload,
          };
    default:
      return referral;
  }
}