const IconTransactions = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.1301 15.3496C16.1301 15.7806 15.7806 16.1301 15.3496 16.1301H12.2797C11.8486 16.1301 11.4992 15.7806 11.4992 15.3496C11.4992 14.9185 11.8486 14.5691 12.2797 14.5691H15.3496C15.7806 14.5691 16.1301 14.9186 16.1301 15.3496ZM19.9805 6.19188V16.878C19.9805 18.5995 18.58 20 16.8585 20H3.12195C1.40051 20 0 18.5995 0 16.878V3.12195C0 1.40051 1.40051 0 3.12195 0H5.95044C6.78431 0 7.56831 0.324722 8.15797 0.91438L10.3135 3.06989H16.8585C18.58 3.06993 19.9805 4.4704 19.9805 6.19188ZM18.4195 6.19188C18.4195 5.33116 17.7193 4.63091 16.8585 4.63091H9.99024C9.78322 4.63091 9.5847 4.54868 9.43832 4.4023L7.05421 2.01819C6.75942 1.72336 6.36741 1.56098 5.95044 1.56098H3.12195C2.26123 1.56098 1.56098 2.26123 1.56098 3.12195V16.878C1.56098 17.7388 2.26123 18.439 3.12195 18.439H16.8585C17.7193 18.439 18.4195 17.7388 18.4195 16.878V6.19188Z"
        fill="#939BB1"
      />
    </svg>
  );
};

export default IconTransactions;
