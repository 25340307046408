import React, { useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import { useSnackbar } from 'notistack';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ROUTES } from 'common/constants/routing';
import { MobileImages } from 'common/constants/image-resource';
import { useGetBalance } from 'services/walletService';
import NumFormat from 'component/shared/numformat/NumFormat';
import { MemberBalanceContext } from 'component/shared/provider/MemberBalanceProvider';
import { isUserLoggedIn } from 'common/helpers/localStorage';
import styles from './MemberBalance.module.css';
import { silenceError } from 'common/helpers/helper';

const MemberBalance = ({ handleCloseGame }) => {
  const [{ data }, refetch] = useGetBalance(true);
  const { memberBalance, setMemberBalance } = useContext(MemberBalanceContext);
  // const [memberBalance, setMemberBalance] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  // const { enqueueSnackbar } = useSnackbar();
  const isLoggedIn = isUserLoggedIn();

  useEffect(() => {
    if (isLoggedIn) {
      silenceError(refetch());
    }
  }, [refetch, isLoggedIn]);

  useEffect(() => {
    if (data) {
      setMemberBalance(data.truncatedBalance);
    }
  }, [data, setMemberBalance]);

  // useEffect(() => {
  //   if (balanceError && isLoggedIn) {
  //     enqueueSnackbar(balanceError, {
  //       variant: 'error',
  //       autoHideDuration: 2500,
  //     });
  //   }
  // }, [balanceError, isLoggedIn, enqueueSnackbar]);

  const handleToDeposit = () => {
    if (location.pathname !== ROUTES.WALLET.DEPOSIT) {
      navigate(ROUTES.WALLET.DEPOSIT);
    }
  };

  return (
    memberBalance !== null && (
      <>
        {handleCloseGame ? (
          <div className={styles.memberWallet}>
            <LazyLoadImage
              src={MobileImages.coin}
              alt=""
              width={20}
              height={21}
              className={styles.coin}
            />
            {/* <NumFormat className={styles.memberBalance} value={memberBalance} /> */}
            <span className={styles.memberBalance}>In Play...</span>
            <span className={styles.walletBtn}>
              <LazyLoadImage
                onClick={(e) => {
                  handleCloseGame(e);
                  navigate(ROUTES.WALLET.DEPOSIT);
                }}
                src={MobileImages.wallet}
                alt=""
                width={20}
                height={10}
                className={styles.wallet}
              />
            </span>
          </div>
        ) : (
          <div onClick={handleToDeposit}>
            <div className={styles.memberWallet}>
              <LazyLoadImage
                src={MobileImages.coin}
                alt=""
                width={20}
                height={21}
                className={styles.coin}
              />
              <NumFormat className={styles.memberBalance} value={memberBalance} />
              <span className={styles.walletBtn}>
                <LazyLoadImage
                  src={MobileImages.wallet}
                  alt=""
                  width={20}
                  height={10}
                  className={styles.wallet}
                />
              </span>
            </div>
          </div>
        )}
      </>
    )
  );
};

export default MemberBalance;
