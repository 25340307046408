import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { NavLink, useNavigate } from 'react-router-dom';
import { ROUTES } from 'common/constants/routing';
import IconBonus from 'component/mobile/common/icon/IconBonus';
import IconSlots from 'component/mobile/common/icon/IconSlots';
// import IconCasino from 'component/mobile/common/icon/IconCasino';
import styles from './Footer.module.css';
import IconHome from 'component/mobile/common/icon/IconHome';
import IconSupport from 'component/mobile/common/icon/IconSupport';
import IconMoney from 'component/mobile/common/icon/IconMoney';

import { isIOS } from 'common/helpers/helper';
import { ANDROID_IOS_TAB } from 'common/constants/tabs';
import { MobileImages } from 'common/constants/image-resource';
import useFetchReferCode from 'services/hook/useFetchReferCode';

const Footer = () => {
  const intl = useIntl();
  const selectedTab = isIOS() ? ANDROID_IOS_TAB[1].value : ANDROID_IOS_TAB[0].value;
  const [, , , appView] = useFetchReferCode();
  const navigate = useNavigate();
  // const [isApp, setIsApp] = useState(null);

  // useEffect(() => {
  //   console.log('appview', appView);
  //   setIsApp(appView);
  // }, [appView]);

  const goToDeposit = () => {
    navigate(ROUTES.WALLET.DEPOSIT);
  };

  return (
    <div className={styles.footerWrapper}>
      <div className={styles.footer}>
        <div className={styles.footerNavLeft}>
          <NavLink className={`${styles.navLink}`} to={ROUTES.HOME}>
            {({ isActive }) => (
              <>
                <div className={styles.tab}>
                  <IconHome isActive={true} />
                  <div>{intl.formatMessage({ id: 'footer.home' })}</div>
                </div>
              </>
            )}
          </NavLink>
          <div
            className={`${styles.navLink}`}
            onClick={() => {
              // window.Tawk_API.toggle();
              window.location.href = 'https://direct.lc.chat/18386301/';
            }}
          >
            <div className={styles.tab}>
              <IconSupport isActive={true} />
              <div>{intl.formatMessage({ id: 'footer.support' })}</div>
            </div>
          </div>
        </div>
        {/* <FooterGame /> */}
        <div className={`${styles.navLink} ${styles.footerNavFab}`}>
          <div className={styles.footerNavTop}>
            <div className={styles.footerNavFabIconDiv} onClick={() => goToDeposit()}>
              <IconMoney className={styles.footerNavFabIcon} isActive={true} />
            </div>
          </div>
          <div className={styles.aviatortextDiv}>
            <NavLink to={ROUTES.WALLET.DEPOSIT}>
              {({ isActive }) => (
                <div className={styles.aviatortext}>
                  {intl.formatMessage({ id: 'footer.deposits' })}{' '}
                </div>
              )}
            </NavLink>
          </div>
        </div>
        <div className={`${styles.footerNavRight}`}>
          <div className={`${styles.navLink}`}>
            {appView !== null && appView && (
              <NavLink to={ROUTES.SLOT.HOME}>
                {({ isActive }) => (
                  <>
                    <div className={`${styles.tab} ${styles.slots}`}>
                      <IconSlots isActive={true} />
                      <div>{intl.formatMessage({ id: 'footer.slots' })}</div>
                    </div>
                  </>
                )}
              </NavLink>
            )}
            {appView !== null && !appView && (
              <NavLink to={ROUTES.BONUS.ROOT}>
                {({ isActive }) => (
                  <>
                    <div className={styles.tab}>
                      <IconBonus isActive={true} />
                      <div>{intl.formatMessage({ id: 'footer.bonus' })}</div>
                    </div>
                  </>
                )}
              </NavLink>
            )}
          </div>
          <div className={styles.navLink}>
            {appView !== null && appView && (
              <NavLink to={ROUTES.BONUS.ROOT}>
                {({ isActive }) => (
                  <>
                    <div className={styles.tab}>
                      <IconBonus isActive={true} />
                      <div>{intl.formatMessage({ id: 'footer.bonus' })}</div>
                    </div>
                  </>
                )}
              </NavLink>
            )}
            {appView !== null && !appView && (
              <NavLink to={ROUTES.APP_INSTALATION.ROOT} state={{ selectedTab }}>
                {({ isActive }) => (
                  <>
                    <div className={`${styles.appIconTab}`}>
                      <div className={styles.appIcon}>
                        <img
                          src={MobileImages.footer.appIcon}
                          width={22}
                          height={22}
                          alt="appDownload"
                        />
                      </div>
                      <div>{intl.formatMessage({ id: 'footer.app' })}</div>
                    </div>
                  </>
                )}
              </NavLink>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Footer);
