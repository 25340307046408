const IconMenu = ({ isActive, color }) => {
  return (
    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6974_3108)">
        <path
          d="M18.7007 3.49862H6.68505C5.96785 3.49862 5.38574 2.71577 5.38574 1.74931C5.38574 0.782849 5.96701 0 6.68505 0H18.7007C19.4179 0 20 0.782849 20 1.74931C20 2.71577 19.4188 3.49862 18.7007 3.49862Z"
          fill="white"
        />
        <path
          d="M18.7007 10.1705H6.68505C5.96785 10.1705 5.38574 9.38764 5.38574 8.42118C5.38574 7.45472 5.96701 6.67188 6.68505 6.67188H18.7007C19.4179 6.67188 20 7.45472 20 8.42118C20 9.38764 19.4188 10.1705 18.7007 10.1705Z"
          fill="white"
        />
        <path
          d="M18.7007 16.8419H6.68505C5.96785 16.8419 5.38574 16.059 5.38574 15.0926C5.38574 14.1261 5.96701 13.3433 6.68505 13.3433H18.7007C19.4179 13.3433 20 14.1261 20 15.0926C20 16.059 19.4188 16.8419 18.7007 16.8419Z"
          fill="white"
        />
        <path
          d="M1.74798 3.49862C0.782253 3.49862 0 2.71577 0 1.74931C0 0.782849 0.782253 0 1.74798 0C2.7137 0 3.49595 0.782849 3.49595 1.74931C3.49595 2.71577 2.7137 3.49862 1.74798 3.49862Z"
          fill="white"
        />
        <path
          d="M1.74798 10.1705C0.782253 10.1705 0 9.38764 0 8.42118C0 7.45472 0.782253 6.67188 1.74798 6.67188C2.7137 6.67188 3.49595 7.45472 3.49595 8.42118C3.49595 9.38764 2.7137 10.1705 1.74798 10.1705Z"
          fill="white"
        />
        <path
          d="M1.74798 16.8419C0.782253 16.8419 0 16.059 0 15.0926C0 14.1261 0.782253 13.3433 1.74798 13.3433C2.7137 13.3433 3.49595 14.1261 3.49595 15.0926C3.49595 16.059 2.7137 16.8419 1.74798 16.8419Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6974_3108">
          <rect width="20" height="16.8421" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconMenu;
