const IconVerify = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9998 19.36C16.3459 19.36 20.6798 15.0261 20.6798 9.68C20.6798 4.33388 16.3459 0 10.9998 0C5.65371 0 1.31982 4.33388 1.31982 9.68C1.31982 12.6902 2.69386 15.3795 4.84895 17.1549C4.85238 17.1578 4.84932 17.1632 4.84512 17.1618C4.84253 17.1609 4.83983 17.1629 4.83983 17.1656V19.3999C4.83983 20.5924 6.09677 21.3664 7.16165 20.8296L9.39013 19.7062C9.88686 19.4558 10.4436 19.36 10.9998 19.36Z"
        fill="#4EC33D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8088 6.29599C15.2081 6.60213 15.2843 7.17368 14.9792 7.57375L10.4513 13.5104C10.2917 13.7197 10.0497 13.8498 9.78741 13.8672C9.52516 13.8847 9.26815 13.7878 9.0823 13.6015L6.20846 10.7201C5.85305 10.3637 5.85305 9.78698 6.20846 9.43063C6.56519 9.07295 7.14457 9.07296 7.50131 9.43063L9.63536 11.5703L13.5278 6.46679C13.834 6.06528 14.4081 5.98874 14.8088 6.29599Z"
        fill="white"
      />
    </svg>
  );
};

export default IconVerify;
