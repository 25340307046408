const IconSlots = ({ isActive, color }) => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 2V7.12664H6.97134L7.58184 5.87401H12.2546C8.81056 9.68517 6.94687 14.0605 6.66481 19H11.8553V18.0042C11.8553 12.9144 13.236 8.45167 16 4.61591V2H4Z"
          fill={isActive ? '#FFF' : color}
        />
        <path
          d="M13 9V11.7141H14.7333L15.0894 11.0509H17.8152C15.8062 13.0686 14.719 15.385 14.5545 18H17.5823V17.4728C17.5823 14.7782 18.3877 12.4156 20 10.3849V9H13Z"
          fill={isActive ? '#FFF' : color}
        />
        <path
          d="M0 9V11.7141H1.73328L2.08941 11.0509H4.81518C2.80616 13.0686 1.71901 15.385 1.55447 18H4.58227V17.4728C4.58227 14.7782 5.38768 12.4156 7 10.3849V9H0Z"
          fill={isActive ? '#FFF' : color}
        />
      </svg>
    </>
  );
};

export default IconSlots;
