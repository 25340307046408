import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { setupInterceptors } from './setupInterceptors';
import { useDispatch, useStore } from '../../store/StateProvider';

export function InjectAxiosInterceptors() {
  const dispatch = useDispatch();
  const [store] = useStore();
  const navigate = useNavigate();
  const runOnce = useRef(true);

  useEffect(() => {
    if (runOnce.current) {
      setupInterceptors(navigate, dispatch, store);
    }
    return () => {
      runOnce.current = false;
    };
  }, [navigate, dispatch, store]);

  // not rendering anything
  return null;
}
