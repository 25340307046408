import TextField from '@mui/material/TextField';

const StyledTextField = ({ fontColor, fontWeight, autoComplete = 'off', ...rest }) => {
  return (
    <TextField
      variant="filled"
      fullWidth
      size="small"
      autoComplete={autoComplete}
      sx={{
        input: {
          '&::placeholder': {
            opacity: 1,
            color: 'var(--mui-palette-text-tertiary)',
          },
        },
      }}
      InputLabelProps={{
        shrink: true,
        style: { color: '#535D76', fontSize: 16 },
      }}
      {...rest}
    />
  );
};

export default StyledTextField;
