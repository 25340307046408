const IconDeposit = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 5.36457C10.4315 5.36457 10.7813 5.71434 10.7813 6.14582L10.7813 11.942L12.5205 10.2028C12.8256 9.8977 13.3203 9.8977 13.6254 10.2028C13.9305 10.508 13.9304 11.0026 13.6254 11.3077L10.5524 14.3806C10.2468 14.6861 9.75238 14.6854 9.44754 14.3806L6.37461 11.3077C6.06957 11.0025 6.06953 10.5079 6.37461 10.2028C6.67973 9.8977 7.17441 9.8977 7.47949 10.2028L9.21875 11.942L9.21875 6.14582C9.21875 5.71434 9.56852 5.36457 10 5.36457ZM5.79578 6.9114e-08L14.2042 1.69384e-07C16.8753 2.01236e-07 18.2318 1.81137 18.3982 1.92883C19.4312 2.96164 20 4.335 20 5.79578L20 14.2042C20 15.665 19.4311 17.0383 18.3982 18.0712C18.2335 18.1875 16.8755 20 14.2042 20L5.79578 20C3.12477 20 1.76813 18.1886 1.60176 18.0712C0.568867 17.0383 5.16948e-08 15.665 6.9114e-08 14.2042L1.69384e-07 5.79578C1.86803e-07 4.335 0.568867 2.96164 1.6018 1.92879C1.76633 1.8127 3.12441 3.72583e-08 5.79578 6.9114e-08ZM5.79578 1.5625C3.8384 1.5625 2.87184 2.91488 2.70664 3.03367C1.96879 3.77144 1.5625 4.75238 1.5625 5.79578L1.5625 14.2042C1.5625 15.2476 1.96883 16.2286 2.7066 16.9663C2.87777 17.0895 3.82801 18.4375 5.79578 18.4375L14.2042 18.4375C16.1616 18.4375 17.1279 17.0854 17.2934 16.9664C18.0312 16.2286 18.4375 15.2476 18.4375 14.2042L18.4375 5.79578C18.4375 4.75238 18.0312 3.77144 17.2934 3.03367C17.1222 2.91059 16.172 1.5625 14.2042 1.5625L5.79578 1.5625Z"
        fill="#939BB1"
      />
    </svg>
  );
};

export default IconDeposit;
