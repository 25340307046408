import { borderRadius, color } from '@mui/system';

export const theme = {
  colorSchemes: {
    light: {
      // palette for light mode
      palette: {
        bg: {
          primary: '#04081F',
          secondary: '#0E122A',
          tertiary: '#1A243E',
          quaternary: '#223858',
          fifth: '#101A31',
          action1: 'linear-gradient(to bottom, #29BEFF, #059AFF)',
          action2: 'linear-gradient(180deg, #12162A 0%, #2B3045 100%)',
          active: '#1883FF',
          warning: '#FCBC00',
        },
        border: {
          primary: '#29BEFF',
          warning: '#FCBC00',
          success: '#149F00',
          danger: '#D81A00',
          action1: 'linear-gradient(0deg, #272C43 0%, #202437 100%)',
        },
        text: {
          primary: '#F5F5F5',
          secondary: '#FFFFFF',
          tertiary: '#59657A',
          success1: '#4CC612', // brighter
          success2: '#149F00', // darker
          danger1: '#FC0B36', // brighter
          danger2: '#D81A00', // darker
          warning: '#FCBC00',
          info: '#3E80FF',
          link: '#8FEAFD',
        },
        common: {
          txtWhite: '#ffff',
          txtYellow: '#FCBC00',
          txtInnerBg:'#131732',
        },
        // Component specific
        component: {
          bg: {
            footer: '#101A31',
          },
        },
        image: {},
      },
    },
    dark: {
      // palette for dark mode
      palette: {
        bg: {
          primary: '#04081F',
          secondary: '#0E122A',
          tertiary: '#1A243E',
          quaternary: '#223858',
          fifth: '#101A31',
          action1: 'linear-gradient(to bottom, #29BEFF, #059AFF)',
          action2: 'linear-gradient(180deg, #12162A 0%, #2B3045 100%)',
          active: '#1883FF',
          warning: '#FCBC00',
        },
        border: {
          primary: '#29BEFF',
          warning: '#FCBC00',
          success: '#149F00',
          danger: '#D81A00',
          action1: 'linear-gradient(0deg, #272C43 0%, #202437 100%)',
        },
        text: {
          primary: '#F5F5F5',
          secondary: '#FFFFFF',
          tertiary: '#59657A',
          success1: '#4CC612', // brighter
          success2: '#149F00', // darker
          danger1: '#FC0B36', // brighter
          danger2: '#D81A00', // darker
          warning: '#FCBC00',
          info: '#3E80FF',
          link: '#8FEAFD',
        },
        common: {
          txtWhite: '#ffffff',
          txtYellow: '#FCBC00',
          txtInnerBg:'#131732',
        },
        // Component specific
        component: {
          bg: {
            footer: '#101A31',
          },
        },
        image: {},
      },
    },
  },
  components: {
    MuiPopover: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiPaper-root': {
            backgroundColor: `${theme.palette.bg.secondary} !important`,
            border: 'none !important',
          },
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-selected': {
            border: 'none !important',
            backgroundColor: `${theme.palette.bg.primary} !important`,
            borderRadius: 10,
            color: theme.palette.text.primary,
          },
        }),
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiButton-containedPrimary': {
            background: `${theme.palette.bg.action1} !important`,
          },
        }),
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
          marginBottom: 10,
          '& .MuiTextField-root': {
            marginBottom: 0,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: 'OpenSans, sans-serif',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'OpenSans, sans-serif',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'OpenSans, sans-serif',
          letterSpacing: ' 0.04em',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 4,
          color: theme.palette.text.primary,
          backgroundColor: `${theme.palette.bg.fifth} !important`,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          '& .MuiFilledInput-input': {
            fontFamily: 'OpenSans, sans-serif',
            fontSize: 14,
            height: 'auto',
            paddingTop: 12,
            paddingBottom: 12,
            [theme.breakpoints.up('sm')]: {
              fontSize: 16,
            },
            '&.Mui-disabled': {
              WebkitTextFillColor: theme.palette.text.secondary,
            },
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.bg.fifth,
          },
          '&:hover': {
            backgroundColor: theme.palette.bg.fifth,
          },
          '& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)':
            {
              marginTop: 0,
              paddingLeft: 8,
              paddingRight: 4,
            },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: '0px',
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.bg.fifth,
          },
        }),
        underline: {
          '&:hover:before': {
            borderBottom: '0px',
          },
          '&::before': {
            borderBottom: '0px',
          },
          '&::after': {
            borderBottom: '0px',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontFamily: 'OpenSans, sans-serif',
          color: 'rgba(0, 0, 0, 0.54)',
          '&.MuiInputLabel-root.Mui-focused': {
            color: theme.palette.text.primary,
          },
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiDrawer-paper': {
            backgroundColor: theme.palette.bg.primary,
            backgroundImage: 'none',
          },
        }),
      },
    },
    MuiModal: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          },
        }),
      },
    },
  },
};
