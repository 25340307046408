import React, { useState, useCallback } from 'react';
import { useVerifyPassword } from 'services/accountService';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import IconPassword from 'component/mobile/common/icon/IconPassword';
import CommonPrompt from 'component/mobile/common/commonPrompt/CommonPrompt';
import { AiFillEye } from '@react-icons/all-files/ai/AiFillEye';
import { AiFillEyeInvisible } from '@react-icons/all-files/ai/AiFillEyeInvisible';
import StyledTextField from 'component/shared/styledTextField/StyledTextField';
import { silenceError } from 'common/helpers/helper';
import { MobileImages } from 'common/constants/image-resource';
const VaultPromptPassword = ({
  navigate,
  setSnackbar,
  showPasswordPrompt,
  setShowPasswordPrompt,
  intl,
}) => {
  const [lock, setLock] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    password: '',
  });
  const verifyPassword = useVerifyPassword();

  const submitVerifyPasswordHandler = async () => {
    const submitPasswordData = {
      password: formData.password,
    };

    const resultData = await silenceError(verifyPassword({ data: submitPasswordData }));
    return resultData.data;
  };
  const submitEvent = useCallback(
    async (e) => {
      e.preventDefault();
      if (formData.password === '') {
        setSnackbar('Please fill in your password');
        return;
      }
      if (!lock) {
        setLock(true);
        let resultData = await submitVerifyPasswordHandler();
        if (resultData.data) {
          // setSnackbar('Password Correct', 'success');
          setShowPasswordPrompt(false);
        } else {
          setSnackbar('Password incorrect');
          setLock(false);
        }
      }
    },
    [formData, lock, submitVerifyPasswordHandler],
  );

  const cancelEvent = () => {
    navigate(-1);
  };

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  const handleChange = (prop) => (e) => {
    setFormData((prevData) => ({ ...prevData, [prop]: e.target.value }));
  };
  return (
    <>
      <CommonPrompt
        open={showPasswordPrompt}
        titleImage={MobileImages.vault.password_icon}
        title={'Vault Access'}
        cancelEvent={cancelEvent}
        submitEvent={submitEvent}
        message={'Please enter your login password to access the vault'}
        textbox={
          <StyledTextField
            required
            value={formData.password}
            onChange={handleChange('password')}
            placeholder={intl.formatMessage({ id: 'login.password' })}
            InputProps={{
              type: showPassword ? 'text' : 'password',
              startAdornment: (
                <InputAdornment position="start">
                  <IconPassword color="#939BB1" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <AiFillEye color="#535D76" />
                    ) : (
                      <AiFillEyeInvisible color="#535D76" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        }
      />
    </>
  );
};
export default VaultPromptPassword;
