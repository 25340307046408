const IconRakeBack = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3256_4883)">
        <g clipPath="url(#clip1_3256_4883)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.9419 9.07051C16.9049 9.80377 15.4399 10.7978 14.7814 10.7978C14.6498 10.7978 14.6168 10.7652 14.5839 10.7163C14.3205 10.3904 14.3864 9.23346 14.7979 7.29439C14.8308 7.09885 14.7321 6.90331 14.551 6.83813C14.3699 6.75666 14.1559 6.83813 14.0407 6.98479C12.6251 9.08681 11.9666 9.52676 11.6703 9.52676C11.2588 9.52676 10.8308 8.33725 10.4028 5.97451C10.3699 5.79527 10.2053 5.64862 10.0242 5.63232C9.82671 5.63232 9.67856 5.77898 9.64564 5.97451C9.21765 8.32095 8.78967 9.52676 8.37815 9.52676C8.08185 9.52676 7.42341 9.08681 6.00777 6.98479C5.89255 6.82184 5.69502 6.75666 5.49749 6.83813C5.31642 6.91961 5.21765 7.11514 5.25057 7.29439C5.6621 9.23346 5.72794 10.3904 5.46456 10.7163C5.43164 10.7489 5.39872 10.7978 5.26703 10.7978C4.6086 10.7978 3.14358 9.82007 2.10654 9.07051C1.94193 8.95645 1.71148 8.97274 1.57979 9.1194C1.4481 9.26605 1.41518 9.47788 1.53041 9.64083C3.91724 12.6554 3.45633 16.1098 3.45633 16.1098C3.45633 16.1587 3.45633 16.2076 3.45633 16.2565C3.45633 17.1038 4.65798 17.446 5.71148 17.6415C6.86374 17.8534 8.37815 17.9837 10.0078 17.9837H10.1065C11.7197 17.9837 13.2341 17.8534 14.4028 17.6415C15.4563 17.446 16.658 17.1038 16.658 16.2565C16.658 16.2076 16.658 16.1587 16.658 16.1098C16.658 16.1098 16.2135 12.6554 18.5839 9.64083C18.6991 9.47788 18.6827 9.24975 18.5345 9.1194C18.3864 8.97274 18.1724 8.95645 18.0078 9.07051H17.9419ZM10.0572 16.8268H9.95839C7.39049 16.8268 5.69502 16.5172 4.95428 16.2728C5.71148 16.0121 7.40695 15.7188 9.95839 15.7188H10.0572C12.6251 15.7188 14.3205 16.0284 15.0613 16.2728C14.3041 16.5335 12.6086 16.8268 10.0572 16.8268Z"
            fill="#939BB1"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.2589 3.9049C11.2589 4.58928 10.6992 5.1433 10.0079 5.1433C9.31651 5.1433 8.75684 4.58928 8.75684 3.9049C8.75684 3.22052 9.31651 2.6665 10.0079 2.6665C10.6992 2.6665 11.2589 3.22052 11.2589 3.9049Z"
            fill="#939BB1"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.76157 5.40401C5.76157 5.97432 5.30067 6.43058 4.72454 6.43058C4.1484 6.43058 3.6875 5.97432 3.6875 5.40401C3.6875 4.83369 4.1484 4.37744 4.72454 4.37744C5.30067 4.37744 5.76157 4.83369 5.76157 5.40401Z"
            fill="#939BB1"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.2469 5.40401C16.2469 5.97432 15.786 6.43058 15.2099 6.43058C14.6338 6.43058 14.1729 5.97432 14.1729 5.40401C14.1729 4.83369 14.6338 4.37744 15.2099 4.37744C15.786 4.37744 16.2469 4.83369 16.2469 5.40401Z"
            fill="#939BB1"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.39904 8.3369C1.39904 8.72798 1.08628 9.03758 0.691217 9.03758C0.296156 9.03758 -0.0166016 8.72798 -0.0166016 8.3369C-0.0166016 7.94583 0.296156 7.63623 0.691217 7.63623C1.08628 7.63623 1.39904 7.94583 1.39904 8.3369Z"
            fill="#939BB1"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.9996 8.3369C19.9996 8.72798 19.6869 9.03758 19.2918 9.03758C18.8967 9.03758 18.584 8.72798 18.584 8.3369C18.584 7.94583 18.8967 7.63623 19.2918 7.63623C19.6869 7.63623 19.9996 7.94583 19.9996 8.3369Z"
            fill="#939BB1"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3256_4883">
          <rect width="20" height="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_3256_4883">
          <rect width="20" height="15.3333" fill="white" transform="translate(0 2.6665)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconRakeBack;
