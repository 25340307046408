const IconClose2 = ({ color = '#939BB1', width = 15, height = 15 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.06445"
        y="0.000488281"
        width="19.566"
        height="1.50508"
        rx="0.75254"
        transform="rotate(45 1.06445 0.000488281)"
        fill={color}
      />
      <rect
        x="14.8994"
        y="1.06445"
        width="19.566"
        height="1.50508"
        rx="0.75254"
        transform="rotate(135 14.8994 1.06445)"
        fill={color}
      />
    </svg>
  );
};

export default IconClose2;
