const IconEmail = ({ isActive, color }) => {
  return (
    <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3239_3888)">
        <path
          d="M21.5651 1.41895L14.6572 7.40851L21.7111 14.365C22.052 13.8294 22.2537 13.1963 22.2537 12.5216V3.47808C22.2537 2.7059 21.9894 1.99634 21.5581 1.41895H21.5651Z"
          fill={`${isActive ? '#ffffff' : color}`}
        />
        <path
          d="M20.5216 0.486956C20.0068 0.187826 19.4155 0 18.7825 0H3.47811C2.78246 0 2.1355 0.208696 1.58594 0.570435L10.9494 8.79304L20.5216 0.486956Z"
          fill={`${isActive ? '#ffffff' : color}`}
        />
        <path
          d="M11.4089 10.2332C11.2767 10.3445 11.1167 10.4002 10.9498 10.4002C10.7828 10.4002 10.6228 10.3445 10.4906 10.2263L8.50108 8.48019L1.56543 15.4158C2.11499 15.7845 2.77586 16.0002 3.47847 16.0002H18.7828C19.5133 16.0002 20.195 15.7637 20.7585 15.3741L13.6072 8.32715L11.4089 10.2332Z"
          fill={`${isActive ? '#ffffff' : color}`}
        />
        <path
          d="M0.591304 1.54443C0.215652 2.10096 0 2.76182 0 3.47835V12.5218C0 13.2314 0.215652 13.8853 0.584348 14.4349L7.45739 7.56182L0.591304 1.54443Z"
          fill={`${isActive ? '#ffffff' : color}`}
        />
      </g>
      <defs>
        <clipPath id="clip0_3239_3888">
          <rect width="22.2609" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconEmail;
