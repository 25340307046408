import { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { setLocalStorage, getLocalStorage } from 'common/helpers/localStorage';
import { STORAGE } from 'common/constants/keys';

export default function useFetchReferCode() {
  const { search } = useLocation();
  const params = useMemo(() => {
    return queryString.parse(search);
  }, [search]);
  const [referralCode, setReferralCode] = useState('');
  const [memberReferralCode, setMemberReferralCode] = useState('');
  const [clickId, setClickId] = useState('');
  // const [appsflyerAppId, setAppsflyerAppId] = useState('');
  // const [thirdPartyAppsFlyerId, setThirdPartyAppsFlyerId] = useState('');
  const [fromUniApp, setFromUniApp] = useState(false);
  const [appView, setAppView] = useState(null);
  const initFetchCode = useCallback(() => {
    if (params.referralCode) {
      setReferralCode(params.referralCode);
      setLocalStorage(STORAGE.ReferralCode, params.referralCode);
    } else {
      setReferralCode(getLocalStorage(STORAGE.ReferralCode));
    }

    if (params.memberReferralCode) {
      setMemberReferralCode(params.memberReferralCode);
      setLocalStorage(STORAGE.MemberReferralCode, params.memberReferralCode);
    } else {
      setMemberReferralCode(getLocalStorage(STORAGE.MemberReferralCode));
    }

    if (params.clickid) {
      setClickId(params.clickid);
      setLocalStorage(STORAGE.ClickId, params.clickid);
    } else {
      setClickId(getLocalStorage(STORAGE.ClickId));
    }

    //appId, appsflyerId are used for thirdparty agent app apk log appsflyer event
    if (params.appid) {
      //apk package name
      // setAppsflyerAppId(params.appid);
      setLocalStorage(STORAGE.AppsflyerAppId, params.appid);
    } else {
      // setAppsflyerAppId(getLocalStorage(STORAGE.AppsflyerAppId));
    }

    if (params.appsflyerid) {
      // setThirdPartyAppsFlyerId(params.appsflyerid);
      setLocalStorage(STORAGE.ThirdPartyAppsflyerId, params.appsflyerid);
    } else {
      // setThirdPartyAppsFlyerId(getLocalStorage(STORAGE.ThirdPartyAppsflyerId));
    }

    let isApp = false;

    if (params.fromuniapp) {
      setFromUniApp(params.fromuniapp);
      // setAppView(true);
      isApp = true;
      setLocalStorage(STORAGE.FromUniApp, params.fromuniapp);
      setLocalStorage(STORAGE.AppView, true);
    } else {
      setFromUniApp(getLocalStorage(STORAGE.FromUniApp));
      const app = getLocalStorage(STORAGE.AppView);
      if (app) {
        // setAppView(true);
        isApp = true;
      }
    }

    //for third agent apk pass app param in url
    if (params.isApp) {
      isApp = true;
      setLocalStorage(STORAGE.AppView, true);
    } else {
      const app = getLocalStorage(STORAGE.AppView);
      if (app) {
        isApp = true;
      }
    }

    if (window.FROM_APP) {
      // setAppView(true);
      isApp = true;
      setLocalStorage(STORAGE.AppView, true);
    }

    setAppView(isApp);
  }, [params]);

  useEffect(() => {
    initFetchCode();
  }, [initFetchCode]);

  return [referralCode, memberReferralCode, clickId, appView, fromUniApp];
}
