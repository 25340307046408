import { STORAGE } from '../constants/keys';

export const setSessionStorage = (key, value) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    console.log(err);
  }
};

export const getSessionStorage = (key) => {
  try {
    const value = sessionStorage.getItem(key);
    if (value === null || value === 'undefined') return undefined;
    return JSON.parse(value);
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

export const deleteFromSessionStorage = (key) => {
  try {
    sessionStorage.removeItem(key);
  } catch (err) {
    console.log(err);
  }
};

//#region User
export const ssGetUID = () => getSessionStorage(STORAGE.UID);

export const ssSetUID = (value) => setSessionStorage(STORAGE.UID, value);

export const ssDeleteUID = () => deleteFromSessionStorage(STORAGE.UID);
//#endregion
