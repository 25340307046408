import React, { useState, createContext, useMemo } from 'react';

export const MemberBalanceContext = createContext(null);

const MemberBalanceProvider = ({ children }) => {
  const [memberBalance, setMemberBalance] = useState(null);

  const value = useMemo(
    () => ({
      memberBalance: memberBalance,
      setMemberBalance: setMemberBalance,
    }),
    [memberBalance],
  );

  return <MemberBalanceContext.Provider value={value}>{children}</MemberBalanceContext.Provider>;
};

export default MemberBalanceProvider;
