import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { ssGetUID } from 'common/helpers/sessionStorage';
import { MemberBalanceContext } from 'component/shared/provider/MemberBalanceProvider';
import { useSnackbar } from 'notistack';
import { useContext, useEffect } from 'react';
import { useGetBalance } from 'services/walletService';

export function useSignalR() {
  let connection = null;
  const reconnectDelays = [0, 2000, 5000];
  const { enqueueSnackbar } = useSnackbar();
  const [{ data }, refetch] = useGetBalance(true);
  const { setMemberBalance } = useContext(MemberBalanceContext);

  const startConnection = async (memberId) => {
    try {
      const uid = ssGetUID();
      if (uid) {
        connection = new HubConnectionBuilder()
          .withUrl(`${window.SIGNALR_NOTI_HUB_URL}?memberId=${memberId}&user=${uid}`) // Connect to the hub
          .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: (retryContext) => {
              return retryContext.previousRetryCount < reconnectDelays.length ? reconnectDelays[retryContext.previousRetryCount] : 10000;
            },
          })
          .configureLogging(LogLevel.None)
          .build();

        await connection.start(); // Start the connection
        console.log('SignalR Connected!');

        connection.on('UpdateBalance', (result) => {
          refetch();
          enqueueSnackbar(result.message, {
            variant: result.isSuccess ? 'success' : 'error',
            autoHideDuration: 2500,
          });
        });
      }
    } catch (error) {
      // console.error('Error while establishing SignalR connection:', error);
    }
  };

  const stopConnection = async () => {
    try {
      if (connection) {
        await connection.stop(); // Stop the connection
        console.log('SignalR Disconnected!');
      }
    } catch (error) {
      // console.error('Error while stopping SignalR connection:', error);
    }
  };

  useEffect(() => {
    if (data) {
      setMemberBalance(data.truncatedBalance);
    }
  }, [data, setMemberBalance]);

  // // Start the connection when the component using the hook mounts
  // useEffect(() => {
  //   startConnection();

  //   // Clean up the SignalR connection when the component unmounts
  //   return () => {
  //     stopConnection();
  //   };
  // }, []);

  // Return the functions that can be used in the component using the hook
  return {
    startConnection,
    stopConnection,
  };
}
