export const ACTIONS = {
  USER: {
    USER_DETAILS_SET: 'USER_DETAILS_SET',
    USER_LOGOUT: 'USER_LOGOUT',
    USER_BALANCE: 'USER_BALANCE',
    SPIN_CHANCE: 'SPIN_CHANCE',
    ISEMAIL_OTP_ACTIVE : 'ISEMAIL_OTP_ACTIVE'
  },
  COMMON: {
    AFFILIATESNACKBAR: 'AFFILIATESNACKBAR',
    WELCOMESNACKBAR: 'WELCOMESNACKBAR',
    COMMON_SET: 'COMMON_SET',
    COMMON_CLEAR: 'COMMON_CLEAR',
  },
  IP_ADDRESS: {
    IP_ADDRESS_SET: 'IP_ADDRESS_SET',
    IP_ADDRESS_CLEAR: 'IP_ADDRESS_CLEAR',
  },
  ERROR: {
    ERROR_SET: 'ERROR_SET',
    ERROR_CLEAR: 'ERROR_CLEAR',
  },
  MESSAGE: {
    MESSAGE_UNREAD_COUNT: 'MESSAGE_UNREAD_COUNT',
    MESSAGE_CLEAR: 'MESSAGE_CLEAR',
  },
  REFERRAL: {
    REFERRAL_SPIN_DETAILS: 'REFERRAL_SPIN_DETAILS',
    AWARD_SPIN_AMOUNT: 'AWARD_SPIN_AMOUNT',
    SHOW_WINNING_AMOUNT:'SHOW_WINNING_AMOUNT',
    POPUP: 'POPUP_REFERRAL_BONUS',
    FLOATBUTTON: 'FLOATBUTTON_REFERRAL_BONUS',
    FIRSTSPINMODALOPEN: 'FIRST_SPIN_MODAL_OPEN', 
    REFERRAL_BONUS_WON_AMOUNT:'REFERRAL_BONUS_WON_AMOUNT', 
  },
  DAILYLOGINBONUS: {
    POPUP: 'POPUP',
    FLOATBUTTON: 'FLOATBUTTON',
  },
  DAILYWEEKLYBONUS: {
    POPUP: 'POPUP_DAILY_WEEKLY_BONUS',
    FLOATBUTTON: 'FLOATBUTTON_DAILY_WEEKLY_BONUS',
  },
  POPUP: {
    DEPOSITAPPBONUS_POPUP: 'DEPOSITAPPBONUS_POPUP',
    DEPOSITAPPBONUS_FLOATBUTTON: 'DEPOSITAPPBONUS_FLOATBUTTON',
    SYSANNOUNCEMENTPOPUP: 'SYSANNOUNCEMENTPOPUP',
    SYSANNOUNCEMENTLIST: 'SYSANNOUNCEMENTLIST',
  },
  HOME: {
    CURRENTPOPUP: 'CURRENTPOPUP',
    WELCOMEPOPUP:'WELCOMEPOPUP',
    MEMBERDEPOSITPOPUP: 'MEMBERDEPOSITPOPUP',
  }
};
