import React, { useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ROUTES } from 'common/constants/routing';
import { MobileImages } from 'common/constants/image-resource';
import GoBack from 'component/mobile/common/goback/GoBack';
import styles from './TopbarSecondary.module.css';
import { FormattedMessage } from 'react-intl';
import { silenceError } from 'common/helpers/helper';
import { useFetchPageDetail } from 'services/inviteReferralSpinService';
import IconClose2 from 'component/mobile/common/icon/IconClose2';
import Sidebar from './sidebar/Sidebar';
import { isUserLoggedIn } from 'common/helpers/localStorage';
import {
  setShowFirstSpinBonusModalOpen,
} from 'common/reducer-actions/popupActions';
import { useDispatch } from 'store/StateProvider';

const TopbarSecondary = ({
  noPrimaryLogo,
  logoLeft,
  showCloseButton,
  isWelcomeSnackBar,
  isAffiliateSnackBar,
  event,
}) => {
  const [{ data: pageDetail }, fetchPageDetail] = useFetchPageDetail();
  const { state } = useLocation();
  const prevPath = state?.prevPath || null;
  const navigate = useNavigate();
  const isLoggedIn = isUserLoggedIn();
  const dispatch = useDispatch();

  const closeHandler = () => {
    if (prevPath === ROUTES.USER.LUCKY_WHEEL.ROOT) {
      navigate(ROUTES.HOME);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (noPrimaryLogo) silenceError(fetchPageDetail());
  }, [fetchPageDetail, noPrimaryLogo]);

  const backEventHandler = () => {
    setShowFirstSpinBonusModalOpen(dispatch, false);
    navigate(-1);
  };

  return (
    <>
      <div
        className={`${noPrimaryLogo ? styles.headerWithTitle : styles.header} ${
          !logoLeft ? styles.justifyCenter : ''
        } ${
          (isWelcomeSnackBar && !isLoggedIn && showCloseButton && !event) ||
          (isAffiliateSnackBar && isLoggedIn && showCloseButton && !event)
            ? styles.hasTop
            : styles.noneTop
        }`}
        id="topbar-header"
      >
        {!logoLeft && <GoBack arrowClass={styles.backArrow} backEvent={backEventHandler}/>}
        {logoLeft && (
          <div className={styles.leftHeader}>
            <Sidebar />
            <Link to={ROUTES.HOME}>
              <img
                src={MobileImages.logoPrimary}
                width={90}
                height={30}
                alt="logo"
                className={styles.logo}
                // className={`${styles.logo} ${
                //   (isWelcomeSnackBar && !isLoggedIn && showCloseButton) ||
                //   (isAffiliateSnackBar && isLoggedIn && showCloseButton)
                //     ? styles.marginHasTop
                //     : ''
                // }`}
              />
            </Link>
          </div>
        )}{' '}
        {!logoLeft && (
          <div>
            {noPrimaryLogo ? (
              <div className={styles.title}>
                <FormattedMessage
                  id="referral_promotion.ads_title"
                  values={{
                    rewardAmount: <span>{pageDetail?.targetAmount}</span>,
                  }}
                />
              </div>
            ) : (
              <Link to={ROUTES.HOME}>
                <img
                  src={MobileImages.logoPrimary}
                  width={90}
                  height={30}
                  alt="logo"
                  className={styles.logo}
                />
              </Link>
            )}
          </div>
        )}
        {showCloseButton && (
          <div className={styles.closeContainer} onClick={closeHandler}>
            <IconClose2 />
          </div>
        )}
      </div>
      <div
        className={
          (isWelcomeSnackBar && !isLoggedIn && showCloseButton && !event) ||
          (isAffiliateSnackBar && isLoggedIn && showCloseButton && !event)
            ? styles['has-top-header-placeholder']
            : styles['header-placeholder']
        }
      ></div>
    </>
  );
};

export default TopbarSecondary;
