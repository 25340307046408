const IconPhone = ({ isActive, color }) => {
  return (
    <svg width="16" height="22" viewBox="0 0 16 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1656_6535)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.4262 0H13.5772C14.9111 0 16.0034 1.10431 16.0034 2.45287V22.5506C16.0034 23.8991 14.9111 25.0034 13.5772 25.0034H2.4262C1.0923 25.0034 0 23.8991 0 22.5506V2.45287C0 1.10431 1.0923 0 2.4262 0ZM8.03403 22.0827C10.0757 22.0827 10.0757 24.484 8.03403 24.484C5.99234 24.484 5.99234 22.0827 8.03403 22.0827ZM8.03403 22.7329C8.90855 22.7329 8.90855 23.7478 8.03403 23.7478C7.15951 23.7478 7.15951 22.7329 8.03403 22.7329ZM3.22926 1.90244H12.7707C13.9107 1.90244 14.8464 2.84505 14.8464 4.00096V19.3546C14.8464 20.5105 13.9141 21.4531 12.7707 21.4531H3.22926C2.08932 21.4531 1.15355 20.5105 1.15355 19.3546V4.00096C1.15355 2.84849 2.08592 1.90244 3.22926 1.90244Z"
          fill={`${isActive ? '#ffffff' : color}`}
        />
      </g>
      <defs>
        <clipPath id="clip0_1656_6535">
          <rect width="16" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconPhone;
