export const theme = {
  colorSchemes: {
    light: {
      // palette for light mode
      palette: {
        bg: {
          primary: '#151747',
          secondary: '#0D1134',
          tertiary: '#181C41',
          action1: '#025BE8',
          action2: '#1C2257',
        },
        border: {
          warning: '#FCBC00',
          success: '#149F00',
          danger: '#D81A00',
        },
        text: {
          primary: '#FAFAFA',
          secondary: '#939BB1',
          success1: '#4CC612',
          success2: '#149F00',
          danger1: '#FC0B36',
          danger2: '#D81A00',
          warning: '#FCBC00',
          info: '#3E80FF',
        },
        common: {},
        image: {},
      },
    },
    dark: {
      // palette for dark mode
      palette: {
        bg: {
          primary: '#151747',
          secondary: '#0D1134',
          tertiary: '#181C41',
          quaternary: '#151747',
          action1: '#025BE8',
          action2: '#1C2257',
        },
        border: {
          warning: '#FCBC00',
          success: '#149F00',
          danger: '#D81A00',
        },
        text: {
          primary: '#FAFAFA',
          secondary: '#939BB1',
          success1: '#4CC612',
          success2: '#149F00',
          danger1: '#FC0B36',
          danger2: '#D81A00',
          warning: '#FCBC00',
          info: '#3E80FF',
        },
        // Component specific
        component: {
          bg: {
            footer: '#0a0c3c',
          },
        },
        image: {},
      },
    },
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
          marginBottom: 10,
          '& .MuiTextField-root': {
            marginBottom: 0,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: 'OpenSans, sans-serif',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'OpenSans, sans-serif',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'OpenSans, sans-serif',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 4,
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.bg.secondary,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          '& .MuiFilledInput-input': {
            fontFamily: 'OpenSans, sans-serif',
            fontSize: 14,
            height: 'auto',
            paddingTop: 12,
            paddingBottom: 12,
            [theme.breakpoints.up('sm')]: {
              fontSize: 16,
            },
            '&.Mui-disabled': {
              WebkitTextFillColor: theme.palette.text.secondary,
            },
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.bg.secondary,
          },
          '&:hover': {
            backgroundColor: theme.palette.bg.secondary,
          },
          '& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)':
            {
              marginTop: 0,
              paddingLeft: 8,
              paddingRight: 4,
            },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: '0px',
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.bg.secondary,
          },
        }),
        underline: {
          '&:hover:before': {
            borderBottom: '0px',
          },
          '&::before': {
            borderBottom: '0px',
          },
          '&::after': {
            borderBottom: '0px',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontFamily: 'OpenSans, sans-serif',
          color: 'rgba(0, 0, 0, 0.54)',
          '&.MuiInputLabel-root.Mui-focused': {
            color: theme.palette.text.primary,
          },
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiDrawer-paper': {
            backgroundColor: theme.palette.bg.primary,
            backgroundImage: 'none',
          },
        }),
      },
    },
    MuiModal: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          },
        }),
      },
    },
  },
  typography: {
    body1: {
      fontSize: '1rem',
      color: 'red',
      textAlign: 'left',
    },
    body2: {
      fontSize: '0.875rem',
    },
    body3: {
      fontSize: '0.75rem',
      color: 'blue',
    },
    body4: {
      fontSize: '0.625rem',
    },
    subtitle: {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    badgetext: {
      fontSize: '1.625rem',
      fontWeight: 700,
      textTransform: 'uppercase',
      color: 'lime',
    },
  },
};
