import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

const GoBack = ({ backEvent = null, arrowClass = null, ...other }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={
        backEvent !== null
          ? backEvent
          : () => {
              navigate(-1);
            }
      }
      className={arrowClass}
      style={{ position: 'absolute', left: 10, top: 18, fontSize: 23, zIndex: 1300 }}
    >
      <ArrowBackIosRoundedIcon
        color="primary"
        sx={{ fontSize: 26, color: 'var(--mui-palette-text-secondary)' }}
      />
    </div>
  );
};

export default GoBack;
