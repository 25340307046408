import { ACTIONS } from '../constants/actions';

export default function ipAddressReducer(ipAddress, { type, payload = null }) {
  switch (type) {
    case ACTIONS.IP_ADDRESS.IP_ADDRESS_SET:
      return {
        ...ipAddress,
        ...payload,
      };
    case ACTIONS.IP_ADDRESS.IP_ADDRESS_CLEAR:
      return undefined;
    default:
      return ipAddress;
  }
}
