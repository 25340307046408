import { ACTIONS } from '../constants/actions';

export default function errorsReducer(error, { type, payload = null }) {
    switch (type) {
        case ACTIONS.ERROR.ERROR_SET:
            return {
                ...error,
                ...payload,
            };
        case ACTIONS.ERROR.ERROR_CLEAR:
            return undefined;
        default:
            return error;
    }
}
