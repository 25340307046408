import React, { memo } from 'react';
import { VIPLEVELENUM } from 'component/mobile/user/vip/VipEnum';
import styles from './VipLevelFrame.module.css';

const VipLevelFrame = (props) => {
  const { vipLevel, vipLevelName = '', width, height, smallIcon = false } = props;

  const [currentVip] = Object.values(VIPLEVELENUM).filter((item) => item.level === vipLevel);

  if (!currentVip) return null;

  const vipIcon = smallIcon ? currentVip.iconSmall : currentVip.icon;

  return vipLevelName ? (
    <div className={styles.vipItem}>
      <img src={vipIcon} alt="vip-item" width={width} height={height} className={styles.vipIcon} />
      <span className={`${styles.vipName} ${styles[`level${currentVip.level}`]}`}>
        {vipLevelName}
      </span>{' '}
    </div>
  ) : (
    <img
      src={vipIcon}
      alt="vip-item"
      width={width}
      height={height}
      className={`${styles.vipIcon} ${styles[`vipIcon${vipLevel}`]}`}
    />
  );
};

export default memo(VipLevelFrame);
