const IconRegister2 = ({ isActive, color }) => {
  return (
    <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.6667 19.75V17.6667C17.6667 16.5616 17.2277 15.5018 16.4463 14.7204C15.6649 13.939 14.6051 13.5 13.5 13.5H5.16667C4.0616 13.5 3.00179 13.939 2.22039 14.7204C1.43899 15.5018 1 16.5616 1 17.6667V19.75M17.667 9.3335H23.917M20.792 6.2085V12.4585M13.5003 5.16667C13.5003 7.46785 11.6348 9.33333 9.33366 9.33333C7.03247 9.33333 5.16699 7.46785 5.16699 5.16667C5.16699 2.86548 7.03247 1 9.33366 1C11.6348 1 13.5003 2.86548 13.5003 5.16667Z"
        stroke="url(#paint0_linear_320_3)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_320_3"
          x1="3.00611"
          y1="5.69351"
          x2="20.3206"
          y2="18.5039"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.9999" stopColor={`${isActive ? '#ffffff' : color}`} />
          <stop offset="1" stopColor={`${isActive ? '#ffffff' : color}`} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconRegister2;
